import React from 'react';
import { Route  } from 'react-router-dom';
import { connect } from 'react-redux';

const RestrictedRoutes = (props) => {
    const {menu, nombre, path, component} = props;

    return (
        menu.map((ruta, i) => 
            (nombre === ruta.componente &&<Route key={i} path={path} component={component} />)
        )        
    )
};

export default connect(
    (state) => ({
        menu: state.auth.menu,
    }), {  }) (RestrictedRoutes);


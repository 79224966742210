import React, { Component } from 'react';
import Suggestions from './Suggestions';
import './SearchStyles.css';

class Search extends Component {
    constructor() {
        super();
        this.state = {
            query: '',
            data: [],
            defaultLat: 29.0729673,
            defaultLng: -110.9559192,
            lugar: {
                id: -1,
                nombre: ''
            },
            cursor:-1
        }
        this.swHillo = { lat: 28.9875843, lng: -111.0847417 };
        this.neHillo = { lat: 29.176726, lng: -110.89323179999997 };
        this.defaultBounds = new window.google.maps.LatLngBounds(this.swHillo, this.neHillo);
        this.autocompleteService = new window.google.maps.places.AutocompleteService();
        
        this.placeSelected = this.placeSelected.bind(this);

        this.handleKeys = this.handleKeys.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
    }

    getsuggestions = (input) => {
        this.autocompleteService.getPlacePredictions({
            input,
            componentRestrictions: { country: 'mx' },
            bounds: this.defaultBounds
        }, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                this.setState({
                    data: results
                })
            }
        });
    }

    resetSearch(){
        this.setState({
            query: '',
            data: [],
            lugar: {
                id: -1,
                nombre: ''
            },
            cursor:-1
        });
    }

    placeSelected(place) {
        this.setState({
            lugar: {
                id: place.place_id,
                nombre: place.description
            },
            data: [],
            query: place.description,
        });
    }

    handleInputChange = (e) => {
        const {target : {value}} = e;
        this.setState({    
            cursor:-1,        
            /*query: this.search.value*/
            query: value
        }, () => {
            if (this.state.query && this.state.query.length > 1) {
                if (this.state.query.length % 2 === 0) {
                    this.getsuggestions(this.state.query)
                }
            } else if (!this.state.query) {
                this.setState({
                    data: [],
                });
            }
        })
    }

    handleKeys(event) {
        const { data, cursor } = this.state;
        if (data.length === 0) { return; }
        let ul = document.getElementById('ul_suggestions');
        let ul_count= ul.childNodes.length;
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                if (cursor === -1) {
                    ul.childNodes[0].focus();
                    this.setState({cursor : 0});
                } else {
                    let next = cursor + 1; 
                    if(next< ul_count){     
                        ul.childNodes[next].focus();
                        this.setState({cursor : next});
                    }
                }
                break;
            case 'ArrowUp':
                event.preventDefault();
                if(cursor >0){
                    let prev = cursor - 1;
                    if(prev >= 0 ){
                        ul.childNodes[prev].focus();
                        this.setState({cursor : prev});
                    }
                }
                break;
            case 'Enter':
                if (cursor !== -1) {
                   ul.childNodes[cursor].click();
                }
                break;
            default:
                return;
        }
    }

    render() {
        const { data, query, cursor } = this.state;

        return (
            <form autoComplete="off">
                <input id="txt_busqueda"
                    value={query}
                    /*ref={input => this.search = input}*/
                    onChange={(e) => this.handleInputChange(e)}
                    className="form-control"
                    onKeyDown={this.handleKeys}
                />
                {data.length > 0 && <Suggestions results={data} handleKeys = {this.handleKeys} lugar={this.placeSelected} cursor={cursor} />}
            </form>
        )
    }
}

export default Search;

import { baseUrl, GET, POST, CreateRequest, makeUrlGET } from './general';

export const apiGetMenu = async () => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'menu/';
    return fetch(url, request).then(res => res.json());
};

export const apiHandleAuth = async (correo, password, method) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method
    }
    request.body = JSON.stringify({
        "email": correo,
        "password": password
    });
    let url = baseUrl + 'auth/login/';
    return fetch(url, request).then(res => res.json());
};

export const apiChangePassword = async (correo, method) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method
    }
    request.body = JSON.stringify({
        "email": correo,
    });
    let url = baseUrl + 'password/change/';
    return fetch(url, request).then(res =>
        JSON.stringify(res.status)
    );
};

export const apiEditPassword = async (uuid, method, password) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method
    }
    request.body = JSON.stringify({
        "password": password
    });
    let url = baseUrl + `password/${uuid}/`;
    return fetch(url, request).then(res => {
        if (res.status === 204) return JSON.stringify(res.status)
        if (res.status === '204') return JSON.stringify(res.status)
        else return res.json();
    }
    );
};
import React, { Component } from 'react';
import MapaDetalles from '../../Components/Mapa/Polyline';
import { connect } from 'react-redux';
import { fetchGetTipoVehiculo, fetchGetCatUsuarios } from '../../Reducers/catalogos';
import { fetchMapInfo, resetMapInfo, fetchGetKilometros } from '../../Reducers/historico';
import { fetchGetVehiculoDetalles, fetchHandleModalVehiculo, fetchUpdateVehiculoDetalles } from '../../Reducers/vehiculos';
import moment from 'moment';
import { Button, Tab, Nav, NavItem, Row, Col } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/es'
import AcordionParadas from './Paradas';
import { fetchGetHistorico } from '../../Reducers/alertas';
import { fetchHandleAlertMonitor, fetchHideAlertMonitor, fetchSendCommand } from '../../Reducers/monitor';

import ModalAltaVehiculo from './AltaVehiculo';
import ListaAlertas from '../Alertas/ListaAlertas';
import TotalesGasolina from '../Gasolina/TotalesGasolina';
import RegistrosGasolina from '../Gasolina/RegistrosGasolina';

import { NotificationManager } from 'react-notifications';
import '../Shared/loaderCustom.scss';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

class DetallesVehiculos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dpStart: moment(start),
            dpEnd: moment(end),
            startDate: moment(start).toISOString(),
            endDate: moment(end).toISOString(),
            id: (this.props.location.state.id_vehiculo ? this.props.location.state.id_vehiculo : -1),
            fechas: [
                { dia: moment(start).format('DD/MM/YYYY') }
            ],
            activeTab: 1,
            accion: -1,
        }
        this.buscarInfo = this.buscarInfo.bind(this);
        this.borrarFiltros = this.borrarFiltros.bind(this);
        this.handleTabClick = this.handleTabClick.bind(this);
        this.loadModal = this.loadModal.bind(this);

        this.showAlertMonitor = this.showAlertMonitor.bind(this);
        this.handleStatusVehiculo = this.handleStatusVehiculo.bind(this);
        this.hideAlertaMonitor = this.hideAlertaMonitor.bind(this);

        this.childAlertas = React.createRef();

        this.childMapa = React.createRef();
        this.resetBounds = this.resetBounds.bind(this);
    }

    componentDidMount() {
        const { startDate, endDate, id } = this.state;
        const { getMapInfo, getVehiculo, getKilometraje } = this.props;
        getMapInfo(startDate, endDate, id);
        getVehiculo(id);
        getKilometraje(id, startDate, endDate);
    }

    buscarInfo() {
        const { startDate, endDate, id, activeTab, dpStart, dpEnd } = this.state;
        const { getMapInfo, getKilometraje } = this.props;

        if (!dpStart.isBefore(dpEnd)) {
            NotificationManager.warning('La fecha final debe ser después de la fecha inicial.', 'Rango de fechas inválido');
        }
        else {

            var dateArray = [];
            var currentDate = dpStart;
            var stopDate = dpEnd;
            while (currentDate <= stopDate) {
                dateArray.push({ dia: moment(currentDate).format('DD/MM/YYYY') })
                currentDate = moment(currentDate).add(1, 'days');
            }
            this.setState({
                fechas: dateArray
            });

            getMapInfo(startDate, endDate, id);
            getKilometraje(id, startDate, endDate);

            switch (activeTab) {
                case 2:
                    this.childAlertas.current.updateEvent();
                    break;
                case 3:
                    this.childRegistrosGasolina.updateRegistros();
                    break;
                case 4:
                    this.childTotalesGasolina.updateTotales();
                    break;
            }
        }
    }

    resetBounds() {
        this.childMapa.current.resetCenter();
      }

    borrarFiltros() {
        this.setState({
            dpStart: moment(start),
            dpEnd: moment(end),
            startDate: moment(start).toISOString(),
            endDate: moment(end).toISOString(),
            fechas: [
                { dia: moment(start).format('DD/MM/YYYY') }
            ],
        });
        this.props.reset();

       let inicio = moment(start).toISOString();
        let fin = moment(end).toISOString();

        const { id } = this.state;
        const { getMapInfo, getKilometraje } = this.props;
        getMapInfo(inicio, fin, id);
        getKilometraje(id, inicio, fin);
    }

    diaSemana(numdia) {
        let dia = '';
        switch (numdia) {
            case 1:
                dia = 'Lunes';
                break;
            case 2:
                dia = 'Martes';
                break;
            case 3:
                dia = 'Miércoles';
                break;
            case 4:
                dia = 'Jueves';
                break;
            case 5:
                dia = 'Viernes';
                break;
            case 6:
                dia = 'Sábado';
                break;
            case 0:
                dia = 'Domingo';
                break;
        }
        return (dia);
    }

    handleTabClick(idTab) {
        this.setState({ activeTab: idTab });
    }

    handleChangeDTP(name, value) {
        switch (name) {
            case 'fromDate':
                this.setState({ dpStart: value, startDate: moment(value).toISOString(), });
                break;
            case 'fromTime':
                this.setState({ dpStart: value, startDate: moment(value).toISOString() });
                break;
            case 'toDate':
                this.setState({ dpEnd: value, endDate: moment(value).toISOString() });
                break;
            case 'toTime':
                this.setState({ dpEnd: value, endDate: moment(value).toISOString() });
                break;
            default:
                break;
        }
    }

    loadModal() {
        const { handleModal, getUsuarios } = this.props;
        getUsuarios({ activo: 'true' });
        handleModal();
    }

    showAlertMonitor(tipo) {
        this.props.handleAlertMonitor(tipo);
        this.setState({
            accion: tipo
        });
    }

    handleStatusVehiculo() {
        const { id, accion } = this.state;
        let datos = [];
        switch (accion) {
            case 1: datos = {
                "comando": "110"
            };
                break;
            case 2:
                datos = {
                    "comando": "109"
                };
                break;
        }
        this.props.sendCommand(datos, id, 'Detalles');
    }

    hideAlertaMonitor() {
        this.props.hideAlertMonitor();
        this.setState({ accion: -1 });
    }

    render() {

        const { kilometraje, vehiculo, update, getTiposVehiculos, historialVehiculo, historialAlertas, paradas, reset, tipos, getAlertas, usuarios, handleModal, showModal, alertaMonitor, usuario } = this.props;
        const { startDate, endDate, id, activeTab } = this.state;

        return (
            <div>

                <SweetAlert show={alertaMonitor.show} type={alertaMonitor.type} confirmButtonText={alertaMonitor.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alertaMonitor.title} text={alertaMonitor.text}
                    onConfirm={this.handleStatusVehiculo} onCancel={this.hideAlertaMonitor} />


                <div className="row">
                    <div className="col-md-12">
                        <div className="card" >
                            <div className="card-body">
                               { usuario.tipo.id === 1?
                                 <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-3 col-12" >
                                        <p> Vehículo: {vehiculo.nombre ? vehiculo.nombre : ''}</p>
                                    </div>
                                    <div className="col-md-3 col-12 text-left" >
                                        <div className="text-left"><p>  Estado: {vehiculo.activo ? 'Activo' : 'Inactivo'}</p> </div>
                                    </div>
                                        <div className="col-md-2 col-6">
                                            <a className={vehiculo.apagado ? "btn btn-raised btn-danger" : "btn btn-raised btn-success"} onClick={() => this.showAlertMonitor(vehiculo.apagado ? 1 : 2)} href="#" ><i className="fas fa-power-off"></i></a> &nbsp;&nbsp;
                                        </div>
                                        <div className="col-md-2 col-6 text-right">
                                            <Button type="button" className="btn btn-raised btn-info" onClick={this.loadModal}><i className="fas fa-edit"></i> Editar</Button>
                                        </div>            
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5 col-5" >
                                        <p> Vehículo: {vehiculo.nombre ? vehiculo.nombre : ''}</p>
                                    </div>
                                    <div className="col-md-5 col-5" >
                                        <p className="text-right">  Estado: {vehiculo.activo ? 'Activo' : 'Inactivo'}</p>
                                    </div>                                    
                                </div>
                                }
                                <hr />
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5 col-12">
                                        <div className="row">
                                            <div className="col-md-3 col-5">
                                                <b>Descripción:</b>
                                            </div>
                                            <div className="col-md-9  col-7">
                                                <div className="float-right">{vehiculo.descripcion ? vehiculo.descripcion : ""}</div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <b>Seguro:</b>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="float-right">{vehiculo.seguro ? vehiculo.seguro : ""}</div>
                                            </div>
                                        </div>
                                        <br />
                                        {usuario.tipo.id === 1 &&
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <b>Teléfono GPS:</b>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <div className="float-right">{vehiculo.telefono ? vehiculo.telefono : ""}</div>
                                                </div>
                                            </div>
                                        }
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3 col-3">
                                                <b>Chofer:</b>
                                            </div>
                                            <div className="col-md-9 col-9">
                                                <div className="float-right">
                                                    {vehiculo.usuarios.choferes ?
                                                        vehiculo.usuarios.choferes.map((usuario, index) =>
                                                            index > 0 ? ', ' + usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : ''):
                                                                usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '')
                                                        )
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <b>Teléfono chofer:</b>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="float-right"></div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6 col-8">
                                                <b>Kilómetros recorridos:</b>
                                            </div>
                                            <div className="col-md-6 col-4">
                                                <div className="float-right">{kilometraje.distancia ? Number((kilometraje.distancia).toFixed(3)) : 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col-md-5 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <b>Tipo de vehículo:</b>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="float-right">{vehiculo.tipo ? vehiculo.tipo.nombre : ""}</div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <b>Placas:</b>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="float-right">{vehiculo.placas ? vehiculo.placas : ""}</div>
                                            </div>
                                        </div>
                                        <br />
                                        {usuario.tipo.id === 1 &&
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <b>IMEI:</b>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <div className="float-right">{vehiculo.imei ? vehiculo.imei : ""}</div>
                                                </div>
                                            </div>
                                        }
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3 col-5">
                                                <b>Supervisor:</b>
                                            </div>
                                            <div className="col-md-9 col-7">
                                                <div className="float-right">
                                                    {vehiculo.usuarios.supervisores ?
                                                        vehiculo.usuarios.supervisores.map((usuario, index) =>
                                                            index > 0 ? ', ' + usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '') :
                                                                usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '')
                                                        )
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <b>Horario de trabajo:</b>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="float-right">{vehiculo.horarios ?
                                                    vehiculo.horarios.map((horario, index) =>
                                                        <p key={index}>{this.diaSemana(horario.dia)} de {horario.entrada.slice(0, 5)} a  {horario.salida.slice(0, 5)}</p>
                                                    )
                                                    : ''}</div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2">
                                        <div className="input-group">
                                            <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromDate" locale="es" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("fromDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpStart} timeFormat={false} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="input-group">
                                            <span className="input-group-addon" ><i className="far fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromTime" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("fromTime", moment)} dateFormat={false} value={this.state.dpStart} />
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2">
                                        <div className="input-group">
                                            <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toDate" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("toDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" placeholder="Fecha final" value={this.state.dpEnd} timeFormat={false} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="input-group">
                                            <span className="input-group-addon" ><i className="far fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toTime" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("toTime", moment)} dateFormat={false} value={this.state.dpEnd} />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-7 col-7">
                                        <div className="btn-toolbar" role="toolbar" >
                                            <div className="btn-group " role="group" aria-label="First group">
                                                <Button type="button" className="btn btn-raised btn-primary" onClick={this.borrarFiltros}><i className="fas fa-trash"></i> Borrar filtros</Button>
                                            </div>
                                            <div className="btn-group " role="group" aria-label="Second group">
                                                <Button type="button" className="btn btn-raised btn-info" onClick={this.resetBounds}><i className="far fa-map"></i> Restablecer mapa</Button>
                                            </div>
                                            <div className="btn-group" role="group" aria-label="Third group">
                                                <Button type="button" className="btn btn-raised btn-success" onClick={this.buscarInfo}><i className="fas fa-redo"></i> Actualizar</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-3 text-right">
                                        <Button type="button" className="btn btn-raised btn-info" onClick={this.buscarInfo}><i className="fas fa-search"></i> Buscar</Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <MapaDetalles coordenadas={historialVehiculo.rows} paradas={paradas} resetInfo={reset} ref={this.childMapa} />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <Tab.Container id="tabs_detalles" defaultActiveKey="1">
                                    <Row className="clearfix">
                                        <Col sm={12} className="bottom_nav">
                                            <Nav bsStyle="tabs">
                                                <NavItem eventKey="1" className="nav-link" onClick={() => this.handleTabClick(1)} ><i className="fas fa-stopwatch"></i> Paradas</NavItem>
                                                <NavItem eventKey="2" className="nav-link" onClick={() => this.handleTabClick(2)}><i className="fas fa-exclamation-circle"></i> Alertas</NavItem>
                                                <NavItem eventKey="3" className="nav-link" onClick={() => this.handleTabClick(3)}><i className="fas fa-gas-pump"></i> Registros gasolina</NavItem>
                                                <NavItem eventKey="4" className="nav-link" onClick={() => this.handleTabClick(4)}><i className="fas fa-file-invoice-dollar"></i> Consumo gasolina</NavItem>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content animation>
                                                <Tab.Pane eventKey="1"><AcordionParadas fechas={this.state.fechas} paradas={paradas} /></Tab.Pane>
                                                <Tab.Pane eventKey="2">{activeTab === 2 && <ListaAlertas ref={this.childAlertas} get={getAlertas} alertas={historialAlertas} fecha_incio={startDate} fecha_fin={endDate} idTipo={-1} idChofer={-1} idVehiculo={id} />}</Tab.Pane>
                                                <Tab.Pane eventKey="3">{activeTab === 3 && <RegistrosGasolina ref={connectedComponent => this.childRegistrosGasolina = connectedComponent ? connectedComponent.getWrappedInstance() : ''} fechaInicial={startDate} fechaFinal={endDate} vehiculo={id} />}</Tab.Pane>
                                                <Tab.Pane eventKey="4">{activeTab === 4 && <TotalesGasolina ref={connectedComponent => this.childTotalesGasolina = connectedComponent ? connectedComponent.getWrappedInstance() : ''} inicio={startDate} fin={endDate} vehiculo={id} />}</Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && <ModalAltaVehiculo show={showModal} onHide={showModal ? handleModal : ''} vista='Detalles' tiposVehiculos={tipos}
                    vehiculo={vehiculo} update={update} getTipos={getTiposVehiculos} usuarios={usuarios} />}

            </div>
        );
    }
}

export default connect(state => ({
    historialVehiculo: state.historico.historialVehiculo,
    vehiculo: state.vehiculos.vehiculoSeleccionado,
    paradas: state.historico.paradasVehiculo,
    historialAlertas: state.alertas.historico,
    tipos: state.catalogos.tiposVehiculos,
    showModal: state.vehiculos.showModalVehiculo,
    kilometraje: state.historico.kilometros,
    usuarios: state.catalogos.usuarios,
    alertaMonitor: state.monitor.alertaMonitor,
    usuario: state.auth.user
}), {
        handleModal: fetchHandleModalVehiculo,
        getVehiculo: fetchGetVehiculoDetalles,
        getMapInfo: fetchMapInfo,
        reset: resetMapInfo,
        update: fetchUpdateVehiculoDetalles,
        getTiposVehiculos: fetchGetTipoVehiculo,
        getAlertas: fetchGetHistorico,
        getKilometraje: fetchGetKilometros,
        getUsuarios: fetchGetCatUsuarios,
        handleAlertMonitor: fetchHandleAlertMonitor,
        hideAlertMonitor: fetchHideAlertMonitor,
        sendCommand: fetchSendCommand,
    })(DetallesVehiculos);

import React, { Component } from 'react';
import moment from 'moment';
import Datatable from '../Shared/DataTable';

const initialState = {
    columns: ['Nombre', 'E-mail', 'Teléfono', 'Última actualización', 'Opciones'],
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    estatusTab: 'true',
    buscar: ''
};

const configTable = {
    columnDef: {
        nombre: (value) => (<div>{value.nombre + ' ' + value.apellidoP + ' ' + value.apellidoM}</div>),
        opciones: (value) =>
            (value.activo ?
                <div>
                    <a className="btn btn-success" href="#" ><i className="fas fa-toggle-on fa-2x"></i></a> &nbsp;&nbsp;
                    <a className="btn btn-raised btn-info" href="#" onClick={() => value.edit(value.id_usuario)}  ><i className="fas fa-pencil-alt"></i></a>  &nbsp;&nbsp;
                    <a className="btn btn-raised btn-danger" href="#" onClick={() => value.cambiarEstado(value.id_usuario, 2)}   ><i className="fas fa-trash-alt"></i></a>
                </div>
                :
                <div>
                    <a className="btn btn-danger" href="#" ><i className="fas fa-toggle-off fa-2x"></i></a> &nbsp;&nbsp;
                    <a href="#" className="btn btn-raised btn-activar" onClick={() => value.cambiarEstado(value.id_usuario, 1)} ><i className="fas fa-redo-alt"></i></a>
                </div>
            )
    },
    columnStyle: {}
}

class ListaUsuarios extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
    }

    componentDidMount() {
        const { size } = this.state;
        const { estatus, get, usuario } = this.props;

        let obj = {
            tipoUsuario: usuario,
            activo: estatus,
            limit: size,
            offset: '0'
        }
        get(obj);

        this.setState({ estatusTab: estatus });
    }

    updateEvent(texto) {
        const { size } = this.state;
        const { estatus, get, usuario } = this.props;

        let obj = {
            tipoUsuario: usuario,
            activo: estatus,
            limit: size,
            offset: '0'
        }
        if (texto !== '') {
            obj.text = texto;
        }

        this.setState({ activePage: 1, buscar: texto, estatusTab: estatus });
        get(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size, buscar } = this.state;
        const { estatus, get, usuario } = this.props;
        let pagina;

        let obj = {
            tipoUsuario: usuario,
            activo: estatus,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        if (buscar !== '') {
            obj.text = buscar;
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        get(obj);
    }

    handleTabChange() {
        const { size } = this.state;
        const { estatus, get, usuario } = this.props;

        this.setState({
            activePage: 1,
            offset: '0',
            estatusTab: estatus
        })

        let obj = {
            tipoUsuario: usuario,
            activo: estatus,
            limit: size,
            offset: '0'
        }
        get(obj);
        this.setState({ estatusTab: estatus });
    }

    mapArray(data) {
        const { edit, editState } = this.props;
        let dataTable = data.map((elm) => {
            return {
                nombre: {
                    nombre: elm.nombre,
                    apellidoP: (elm.apellidoP !== null ? elm.apellidoP : ''),
                    apellidoM: (elm.apellidoM !== null ? elm.apellidoM : ''),
                },
                email: elm.email,
                telefono: elm.telefono,
                fecha: moment(elm.updatedAt).format('DD/MM/YYYY HH:mm'),
                opciones: {
                    id_usuario: elm.id,
                    activo: elm.activo,
                    edit: edit,
                    cambiarEstado: editState
                },
            }
        });
        return dataTable;
    }

    render() {
        const { usuarios, estatus } = this.props;
        const { columns, activePage, size, total, estatusTab } = this.state;

        if (estatusTab !== estatus) {
            this.handleTabChange();
        }

        return (
            <div>

                <div className="row">
                    <div className="col-md-12">
                        <Datatable tableProps={{ columns, data: this.mapArray(usuarios.rows), configTable }} total={usuarios.total || total} activePage={activePage}
                            size={size} updateTable={this.updateEvent} paginacionEvent={this.paginationHandler} placeHolderInput=' Nombre/Apellidos/Teléfono/Email' />
                    </div>
                </div>
            </div>
        );
    }
}

export default ListaUsuarios;
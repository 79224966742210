import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './login.css';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

import { connect } from 'react-redux';
import { resetPasswordUser, resetAlert } from '../../Reducers/auth';

const initialState = {
  confirm_password: {
    value: '',
    valid: false,
    touched: false,
  },
  password: {
    value: '',
    valid: false,
    touched: false,
  },
  coinciden:{
    submited: false,
    valid: false
  }
}

class Recuperar extends Component {
  constructor(props) {
    super(props)
    this.doSubmit = this.doSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlert = this.handleAlert.bind(this);

    this.state = initialState;
  }

  handleSubmit(){   
    this.setState({
      coinciden: {
        submited: true,
        valid: this.inputValidation('coinciden', ''),
      },
    });   
   this.doSubmit()
  }

  doSubmit() {
    const { match: { params }, resetPasswordUser } = this.props;
    const { confirm_password, password } = this.state;
    if (confirm_password.valid && password.valid && this.inputValidation('coinciden', '')) {
      resetPasswordUser(params.uuid, password.value);
    } else {
      this.setState({
        confirm_password: {
          ...this.state.confirm_password,
          touched: true,
        },
        password: {
          ...this.state.password,
          touched: true,
        }
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: {
        value: value,
        valid: this.inputValidation(name, value),
        touched: true,
      },
      coinciden: {
        submited: false,
        valid: false,
      }
    });
  }

  inputValidation(name, value) {
    switch (name) {
      case 'password':
        return RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{6,40}/).test(value);
      case 'confirm_password':
        return RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{6,40}/).test(value);
      case 'coinciden':
        const { confirm_password, password } = this.state;
        return (confirm_password.value === password.value);
      default:
        return false;
    }
  }

  handleAlert(){
    this.setState(initialState);
    this.props.resetAlert();
    window.location.href = "/Login";
  }


  render() {
    const { coinciden, password, confirm_password } = this.state;
    const {alerta} = this.props;

    return (
      <div className="login">
       <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText="Aceptar"  title={alerta.title}
       text={alerta.text} onConfirm={this.handleAlert}  />
        <form className="login-form" autoComplete="off">
          <h4><b>Restablecer contraseña.</b></h4>
          <label>Por favor ingrese una nueva contraseña.</label>
          <br />
          <div className="input-group input-group-lg">
            <div className="input-group-addon"><span className="fa fa-lg fa-key" /></div>
            <input type="password" name="password" className="form-control" placeholder="Contraseña" id="password"
              onChange={this.handleChange} required tabIndex="1" defaultValue={password.value}/>
          </div>
          <br />
          <div className="input-group input-group-lg">
            <div className="input-group-addon"><span className="fa fa-lg fa-key" /></div>
            <input type="password" name="confirm_password" className="form-control" placeholder="Confirmar contraseña" id="confirm_password"
              onChange={this.handleChange} required tabIndex="2" defaultValue={confirm_password.value}/>
          </div>
          {((!password.valid && password.touched) || (!confirm_password.valid && confirm_password.touched))  && <p className="form-alert">*La contraseña debe de ser al menos 6 carácteres (40 max), con 1 mayúscula, 1 minúscula, 1 dígito, 1 carácter especial</p>}
          <br />
          {!coinciden.valid && coinciden.submited && <p className="form-alert">*Las contraseñas no coinciden</p>}
          <Button id="btn_enviar" className="btn fa-lg" onClick={this.handleSubmit} tabIndex="3" >Cambiar contraseña</Button>
          <br />
          <br />
          <div className="login-bar text-right">
            <Link className="" to={{ pathname: '/Login' }}><i className="fas fa-sign-in-alt"></i>  Iniciar sesión</Link> &nbsp;&nbsp;
          </div>
        </form>
      </div>
    );
  }
}

export default connect((state) =>
  ({
    alerta : state.auth.alerta
  }), { resetPasswordUser, resetAlert })(Recuperar);

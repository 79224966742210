import React from 'react'

const Suggestions = (props) => {
    const options =  props.results.map((r, i) => (
        <li key={i} tabIndex="-1" className={props.cursor === i ? 'active' : null} onKeyDown={(e) => props.handleKeys(e)} onClick={() => props.lugar(r)} >
            <i className="fas fa-map-marker-alt"></i> &nbsp; 
            <span className="primary">{r.structured_formatting.main_text}</span>  &nbsp;
            <span className="secondary">{r.structured_formatting.secondary_text}</span>
        </li>

    ))
    return <div id="results"><ul tabIndex="0" id='ul_suggestions'>{options}</ul></div>
}

export default Suggestions;
import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetMe = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'usuarios/me/' ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiEditMe = async (body) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'usuarios/me/';
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetUsuarios = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'usuarios/?'+ makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};

export const apiAddUsuario = async (body) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'usuarios/';
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetUsuario = async (id) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `usuarios/${id}/` ;
    return fetch(url, request).then(res => res.json());
};

export const apiUpdateUsuario = async (body, id) =>{
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `usuarios/${id}/` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiRestoreUsuario = async (id) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl + `usuarios/${id}/activar` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiDeleteUsuario = async (id) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl + `usuarios/${id}/desactivar` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};
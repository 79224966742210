import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetHorarios = async (objs) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `horarios/?` + makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};

export const apiAddHorario = async (body) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'horarios/' ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetHorario = async (id) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `horarios/${id}/`  ;
    return fetch(url, request).then(res => res.json());
};

export const apiUpdateHorario = async (body, id) =>{
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `horarios/${id}/` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiDeleteHorario = async (id) =>{
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `horarios/${id}/` ;
    return fetch(url, request).then(res => (res.status));
};

import { baseUrl, GET, POST, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetGasolina = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'gasolina/?'+ makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};

export const apiAddGasolina = async (body) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'gasolina/' ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetInfoRegistro = async (id) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `gasolina/${id}/` ;
    return fetch(url, request).then(res => res.json());
};

export const apiDelInfoRegistro = async (id) =>{
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `gasolina/${id}/` ;
    return fetch(url, request).then(res => JSON.stringify(res.status));
};

export const apiGetCostoGasolina = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'extras/gasolina/' ;
    return fetch(url, request).then(res => res.json());
};
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class AltaUsuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: (props.usuario ? props.usuario.email: ''),
            tipoUsuario: (props.usuario  ? props.usuario.tipoUsuario : null),
            nombre: (props.usuario  ? props.usuario.nombre : ''),
            apellidoP: (props.usuario  ? props.usuario.apellidoP : ''),
            apellidoM: (props.usuario ? props.usuario.apellidoM: ''),
            telefono: (props.usuario  ? props.usuario.telefono : ''),
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();        
        const { add, update, usuario, tipo } = this.props;        
        const { email, tipoUsuario, nombre, apellidoP, apellidoM, telefono } = this.state;
        const datos = {
            email: (email !== undefined ? email : null),
            tipoUsuario: (tipoUsuario !== undefined ? tipoUsuario : tipo),            
            nombre: (nombre !== undefined ? nombre : null),
            apellidoP: (apellidoP !== undefined  ? apellidoP : null), 
            apellidoM: (apellidoM !== undefined && apellidoM !== '' ? apellidoM : null), 
            telefono: (telefono !== undefined && telefono !== '' ? telefono : null), 
        }
        if (!usuario.id) {
           add(datos);
        }
        else {
            update(datos, usuario.id);
        }
    }

    render() {
        const { email, nombre, apellidoP, apellidoM, telefono } = this.state;
  
        return (
            <Modal {...this.props} container={this} aria-labelledby="contained-modal-title" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">
                        Registro de usuario
              </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="col-form-label">Nombre(s)*</label>
                                    <input defaultValue={nombre} type="text" name="nombre" onChange={this.handleChange} className="form-control" id="nombre" required pattern=".*[^ ].*" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="apellidoP" className="col-form-label">Apellido paterno*</label>
                                    <input defaultValue={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} className="form-control" id="apellidoP" required pattern=".*[^ ].*" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="apellidoM" className="col-form-label">Apellido materno</label>
                                    <input defaultValue={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} className="form-control" id="apellidoM" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email" className="col-form-label">Correo electrónico*</label>
                                    <input defaultValue={email} type="text" name="email" onChange={this.handleChange} className="form-control" id="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}(.[a-z]{2,3})?$"/>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="telefono" className="col-form-label">Teléfono (clave del país y lada)</label>
                                    <input defaultValue={telefono} type="text" name="telefono" onChange={this.handleChange} className="form-control" id="telefono" />
                                </div>
                            </div>                           
                        </div>
                        <br />                        
                        <div className="row">
                            <div className="col-md-12 leyenda_obligatorios text-right">
                                * Campos obligatorios
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button className="btn btn-raised btn-info float-right" type="submit" >Guardar</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AltaUsuario;
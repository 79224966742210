import React, { Component } from 'react';
import { LocalTable } from 'wafo-tabla';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { Link } from "react-router-dom";

const configTable = {
    columnDef: {
        estado: (value) => (<span className={value.apagado? "dot_r" : value.socket === 1 ? "dot_v" : "dot_g"}></span>),
        opciones: (value) => (
            <div>
                <Link className="btn btn-raised btn-primary" to={{ pathname: '/Detalles', state: { id_vehiculo: value.id_vehiculo } }}>Ver más</Link> &nbsp;
            <a className="btn btn-raised btn-info" href="#" onClick={(e) => value.zoom(e, value.id_vehiculo)} ><i className="fas fa-map-marker-alt" ></i> Mapa</a> &nbsp;           
            <a className={value.apagado ? "btn btn-raised btn-danger" : "btn btn-raised btn-success"} onClick={() => value.apagarVehiculo(value.id_vehiculo, (value.apagado ? 1 : 2))} href="#" ><i className="fas fa-power-off"></i></a>
            </div>
            )           
    },
    columnStyle: {}
}

const configTableGral = {
    columnDef: {
        estado: (value) => (<span className={value.apagado? "dot_r" : value.socket === 1 ? "dot_v" : "dot_g"}></span>),
        opciones: (value) => (
            <div>
                <Link className="btn btn-raised btn-primary" to={{ pathname: '/Detalles', state: { id_vehiculo: value.id_vehiculo } }}>Ver más</Link> &nbsp;
                <a className="btn btn-raised btn-info" href="#" onClick={(e) => value.zoom(e, value.id_vehiculo)} ><i className="fas fa-map-marker-alt" ></i> Mapa</a> &nbsp;           
            </div>
            )           
    },
    columnStyle: {}
}

class ListaPaginada extends Component {
    constructor() {
        super();
        this.state = {
            columns: ['Estado', 'Nombre', 'Descripción', 'Placas', 'Teléfono', 'Última actualización', 'Opciones'],      
            columnsGral: ['Estado', 'Nombre', 'Descripción', 'Placas', 'Última actualización', 'Opciones']      
        };
    }

    mapArray(data) {
        let dataTable = [];
        const {handleZoom, cambiarApagado, permisos} = this.props;

        data.map((elm) => {
            if (elm.vehiculo) {
                dataTable.push({
                    estado: {
                        apagado: elm.vehiculo? elm.vehiculo.apagado:'',
                        socket: elm.estado
                    },
                    nombre: elm.vehiculo ? elm.vehiculo.nombre : '',
                    descripcion: elm.vehiculo ? elm.vehiculo.descripcion : '',
                    placas: elm.vehiculo ? elm.vehiculo.placas : '',
                    telefono: elm.vehiculo ? elm.vehiculo.telefono : '',
                    fecha: moment(elm.timestampms).format('DD/MM/YYYY HH:mm'),
                    opciones: {
                        id_vehiculo: elm.vehiculo ? elm.vehiculo.id : '',
                        activo: elm.vehiculo ? elm.vehiculo.activo : '',
                        zoom: handleZoom,
                        apagado: elm.vehiculo ? elm.vehiculo.apagado : '',
                        apagarVehiculo: cambiarApagado,
                        permiso: permisos
                    }
                });
            }
        });
        return dataTable;
    }

    mapArrayGral(data) {
        let dataTable = [];
        const {handleZoom} = this.props;

        data.map((elm) => {
            if (elm.vehiculo) {
                dataTable.push({
                    estado: {
                        apagado: elm.vehiculo? elm.vehiculo.apagado:'',
                        socket: elm.estado
                    },
                    nombre: elm.vehiculo ? elm.vehiculo.nombre : '',
                    descripcion: elm.vehiculo ? elm.vehiculo.descripcion : '',
                    placas: elm.vehiculo ? elm.vehiculo.placas : '',
                    fecha: moment(elm.timestampms).format('DD/MM/YYYY HH:mm'),
                    opciones: {
                        id_vehiculo: elm.vehiculo ? elm.vehiculo.id : '',
                        activo: elm.vehiculo ? elm.vehiculo.activo : '',
                        zoom: handleZoom,
                    }
                });
            }
        });
        return dataTable;
    }

    render() {
        const { vehiculos, permisos } = this.props;
        const { columns, columnsGral } = this.state;
        if(permisos === 1){
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            {vehiculos.length > 0 ?
                                <LocalTable responsive configTable={configTable} columns={columns} data={this.mapArray(vehiculos)} localtableClass="table-responsive" />
                                :
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nombre</th>
                                            <th>Vehículo</th>
                                            <th>Placas</th>
                                            <th>Teléfono</th>
                                            <th>Última actualización</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td colSpan="7">No se encontraron registros</td></tr>
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </div>
                </div>
            );

        }
        else{
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            {vehiculos.length > 0 ?
                                <LocalTable responsive configTable={configTableGral} columns={columnsGral} data={this.mapArrayGral(vehiculos)} localtableClass="table-responsive" />
                                :
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nombre</th>
                                            <th>Vehículo</th>
                                            <th>Placas</th>
                                            <th>Última actualización</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td colSpan="7">No se encontraron registros</td></tr>
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </div>
                </div>
            );
        }

       
    }
}

export default ListaPaginada;
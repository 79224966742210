import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import HorariosVehiculo from './Horarios';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';

let optionsSupervisores = [];
let optionsChoferes = [];

const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      border: 0,
      height: "unset",
      background: "transparent",
      borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
      "&:hover": {
        boxShadow: "none"
      },
    }),
    menu: () => ({
      backgroundColor: "white",
      boxShadow: "1px 2px 6px #888888",
      position: "absolute",
      left: 0,
      top: `calc(100% + 1px)`,
      width: "100%",
      zIndex: 2,
    }),
    menuList: () => ({
      overflowY: "auto",
      maxHeight: "150px"
    })
  };
  
class AltaVehiculo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            placas: '',
            descripcion: '',
            seguro: '',
            telefono: '',
            imei: '',
            id_tipo_vehiculo: '',
            rendimiento: '',
            actualizado: false,
            selectedSupervisores: [],
            selectedChoferes: [],
            id_vehiculo: -1
        }
        this.updateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSupervisoresChange = this.handleSupervisoresChange.bind(this);
        this.handleChoferesChange = this.handleChoferesChange.bind(this);
        this.handleSelectOptionsSupervisores = this.handleSelectOptionsSupervisores.bind(this);
        this.handleSelectOptionsChoferes= this.handleSelectOptionsChoferes.bind(this);
        this.child = React.createRef();
    }

    componentDidMount() {
        this.props.getTipos();
    }

    updateState() {
        const { vehiculo } = this.props;

        let choferes = [];
        let supervisores = [];

        if (vehiculo.usuarios.supervisores.length > 0) {
            vehiculo.usuarios.supervisores.map(supervisor =>
                supervisores.push({ value: supervisor.id, label: supervisor.nombre + ' ' + supervisor.apellidoP  +' ' + (supervisor.apellidoM ? supervisor.apellidoM : '')})
            )
        }

        if (vehiculo.usuarios.choferes.length > 0) {
            vehiculo.usuarios.choferes.map(chofer =>
                choferes.push({ value: chofer.id, label: chofer.nombre + ' ' + chofer.apellidoP +' ' + (chofer.apellidoM ? chofer.apellidoM : '')})
            )
        }

        this.setState({
            nombre: vehiculo.nombre,
            placas: vehiculo.placas,
            descripcion: vehiculo.descripcion,
            seguro: vehiculo.seguro,
            telefono: vehiculo.telefono,
            imei: vehiculo.imei,
            id_tipo_vehiculo: vehiculo.tipo.id,
            rendimiento: vehiculo.rendimiento,
            actualizado: true,
            selectedSupervisores: (supervisores.length > 0 ? supervisores : null),
            selectedChoferes: (choferes.length > 0 ? choferes : null),
            id_vehiculo: vehiculo.id
        });
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value.trim() });
    }

    handleSupervisoresChange = (selectedSupervisores) => {
        this.setState({
            ...this.state, selectedSupervisores
        });

    }

    handleChoferesChange = (selectedChoferes) => {
        this.setState({
            ...this.state, selectedChoferes
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let horarios = this.child.current.state;

        let val_domingo = (moment('01/01/1900 ' + horarios.dp0.entrada).isBefore(moment('01/01/1900 ' + horarios.dp0.salida)));
        let val_lunes = (moment('01/01/1900 ' + horarios.dp1.entrada).isBefore(moment('01/01/1900 ' + horarios.dp1.salida)));
        let val_martes = (moment('01/01/1900 ' + horarios.dp2.entrada).isBefore(moment('01/01/1900 ' + horarios.dp2.salida)));
        let val_miercoles = (moment('01/01/1900 ' + horarios.dp3.entrada).isBefore(moment('01/01/1900 ' + horarios.dp3.salida)));
        let val_jueves = (moment('01/01/1900 ' + horarios.dp4.entrada).isBefore(moment('01/01/1900 ' + horarios.dp4.salida)));
        let val_viernes = (moment('01/01/1900 ' + horarios.dp5.entrada).isBefore(moment('01/01/1900 ' + horarios.dp5.salida)));
        let val_sabado = (moment('01/01/1900 ' + horarios.dp6.entrada).isBefore(moment('01/01/1900 ' + horarios.dp6.salida)));

        if (!val_domingo || !val_lunes || !val_martes || !val_miercoles || !val_jueves || !val_viernes || !val_sabado) {
            NotificationManager.warning('La hora final debe ser después de la hora inicial.', 'Rango de horario inválido');
        }
        else {

            const { add, update, vehiculo } = this.props;
            const { nombre, placas, descripcion, seguro, telefono, imei, id_tipo_vehiculo, rendimiento, selectedSupervisores, selectedChoferes } = this.state;

            //Info vehiculo
            const datos = {
                nombre: (nombre !== undefined ? nombre : null),
                descripcion: (descripcion !== undefined ? descripcion : null),
                tipoVehiculo: (id_tipo_vehiculo !== undefined ? id_tipo_vehiculo : 0),
                imei: (imei !== undefined && imei !== '' ? imei : null),
                telefono: (telefono !== undefined && telefono !== '' ? telefono : null),
                placas: (placas !== undefined && placas !== '' ? placas : null),
                seguro: (seguro !== undefined && seguro !== '' ? seguro : null),
                rendimiento: (rendimiento !== undefined && rendimiento !== '' ? rendimiento : null),
            };

            //Info horarios
            let horarios_array = [];
            horarios_array.push(horarios.dp0, horarios.dp1, horarios.dp2, horarios.dp3, horarios.dp4, horarios.dp5, horarios.dp6);
            let post_horarios = {
                vehiculoId: (vehiculo.id ? vehiculo.id : -1),
                dias: []
            };
            let put_horarios = [];
            let delete_horarios = [];

            horarios_array.map(horario => {
                if (!horario.id && horario.selected) {
                    post_horarios.dias.push({
                        dia: horario.dia,
                        entrada: horario.entrada + ':00',
                        salida: horario.salida + ':59'
                    })
                }
                else if (horario.id && horario.selected) {
                    vehiculo.horarios.map(item => {
                        if (item.id === horario.id && (item.entrada !== horario.entrada + ':00' || item.salida !== horario.salida + ':59')) {
                            put_horarios.push({
                                idParam: horario.id,
                                body: {
                                    entrada: horario.entrada + ':00',
                                    salida: horario.salida + ':59'
                                }
                            })
                        }
                    })
                }
                else if (horario.id && !horario.selected) {
                    delete_horarios.push(horario.id);
                }
            });
            //Info Usuarios
            let del_usuarios = { ids: [] };
            let put_usuarios = { ids: [] };
            let usuarios = [];
            let usuariosexistentes = (vehiculo.usuarios.supervisores).concat(vehiculo.usuarios.choferes);
            let usuariosSeleccionados = [];
            if(selectedSupervisores && selectedChoferes){
                usuariosSeleccionados = (selectedSupervisores).concat(selectedChoferes);
            } 
            else if(selectedSupervisores){
                usuariosSeleccionados = selectedSupervisores;
            }
            else if(selectedChoferes){
                usuariosSeleccionados = selectedChoferes;
            }
            else{ }

            if (usuariosSeleccionados !== null) {
                usuariosSeleccionados.map(usuario => {
                    if (usuario) {
                        let obj = usuariosexistentes.find(obj => obj.id === usuario.value);
                        if (!obj) {
                            put_usuarios.ids.push(usuario.value);
                        }
                        usuarios.push(usuario.value);
                    }
                })
            }

            if (usuariosexistentes !== null) {
                usuariosexistentes.map(usuario => {
                    let obj = usuarios.includes(usuario.id);
                    if (!obj) {
                        del_usuarios.ids.push(usuario.id);
                    }
                })
            }

            //Insertar info
              if (!vehiculo.id) {
                  add(datos, post_horarios, put_usuarios);
              }
              else {
                  update(datos, vehiculo.id, post_horarios, put_horarios, delete_horarios, put_usuarios, del_usuarios);
              }
        }     
    }

    handleSelectOptionsSupervisores() {
        optionsSupervisores = [];
        this.props.usuarios.rows.map((usuario) => {
            if (usuario.tipo.id === 2) {
                optionsSupervisores.push({ value: usuario.id, label: usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '')});
            }
        });
        return optionsSupervisores;
    }

    handleSelectOptionsChoferes() {
        optionsChoferes = [];
        this.props.usuarios.rows.map((usuario) => {            
            if (usuario.tipo.id === 3) {
                optionsChoferes.push({ value: usuario.id, label: usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '')});
            }
        });
        return optionsChoferes;
    }

    render() {
        const { nombre, placas, descripcion, seguro, telefono, imei, id_tipo_vehiculo, rendimiento, actualizado, 
            selectedSupervisores, selectedChoferes, id_vehiculo } = this.state;
        const { tiposVehiculos, vehiculo,  } = this.props;
        
        if ((vehiculo.id && !actualizado) || (vehiculo.id !== id_vehiculo) ) {
            this.updateState();
        }

        return (
            <Modal {...this.props} container={this} aria-labelledby="contained-modal-title" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">
                        Registro de vehículo
              </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="col-form-label">Nombre*</label>
                                    <input value={nombre} type="text" name="nombre" onChange={this.handleChange} className="form-control" id="nombre" required pattern=".*[^ ].*" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="descripcion" className="col-form-label">Descripción*</label>
                                    <input value={descripcion} type="text" name="descripcion" onChange={this.handleChange} className="form-control" id="descripcion" required pattern=".*[^ ].*" minLength="5"/>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="placas" className="col-form-label">Placas</label>
                                    <input value={placas} type="text" name="placas" onChange={this.handleChange} className="form-control" id="placas" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="seguro" className="col-form-label">Seguro</label>
                                    <input value={seguro} type="text" name="seguro" onChange={this.handleChange} className="form-control" id="seguro" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="telefono" className="col-form-label">Teléfono de GPS</label>
                                    <input value={telefono} type="text" name="telefono" onChange={this.handleChange} className="form-control" id="telefono" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="imei" className="col-form-label">IMEI GPS</label>
                                    <input value={imei} type="text" name="imei" onChange={this.handleChange} className="form-control" id="imei" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="id_tipo_vehiculo" className="col-form-label">Tipo de vehículo*</label>
                                    <select className="form-control" value={id_tipo_vehiculo || ""} id="id_tipo_vehiculo" name="id_tipo_vehiculo" onChange={this.handleChange} required>
                                        <option value="">- SELECCIONE -</option>
                                        {tiposVehiculos.rows.map(item =>
                                            <option key={item.id} value={item.id}>{item.nombre}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="rendimiento" className="col-form-label">Rendimiento (km/lt)</label>
                                    <input value={rendimiento} type="text" name="rendimiento" onChange={this.handleChange} className="form-control" id="rendimiento" pattern="^\d*\.?\d*$" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12" >
                                <HorariosVehiculo ref={this.child} horarios={vehiculo.horarios} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="supervisores" className="col-form-label">Supervisores</label>
                                <div className="input-group">
                                    <span className="input-group-addon" ><i className="fas fa-user"></i></span>&nbsp;&nbsp;&nbsp;
                                        <Select options={this.handleSelectOptionsSupervisores()} placeholder="- Elegir supervisores -" noOptionsMessage={() => "No se encontraron coincidencias"}
                                            value={selectedSupervisores} styles={customStyles} onChange={this.handleSupervisoresChange} isMulti />                                   
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="choferes" className="col-form-label">Choferes</label>
                                <div className="input-group">
                                    <span className="input-group-addon" ><i className="fas fa-user"></i></span>&nbsp;&nbsp;&nbsp;
                                        <Select options={this.handleSelectOptionsChoferes()} placeholder="- Elegir chofer -" noOptionsMessage={() => "No se encontraron coincidencias"}
                                            value={selectedChoferes} styles={customStyles} onChange={this.handleChoferesChange} isMulti />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12 leyenda_obligatorios text-right">
                                * Campos obligatorios
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button className="btn btn-raised btn-info float-right" type="submit" >Guardar</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AltaVehiculo;
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Notificaciones from'./DropDownAlertas';
import { connect } from 'react-redux';
import { signOut, fetchGetMe } from '../../Reducers/auth';

class Header extends Component {
    constructor(props) {
        super(props);    
        this.cerrarSesion = this.cerrarSesion.bind(this);
      }

      componentDidMount(){
          this.props.fetchGetMe();
      }

    toggleSidenav() {
        document.getElementById('wrapper').classList.toggle('toggled');
    }
    
    cerrarSesion(){        
        this.props.signOut();
    }

    render() {

        const {usuario} = this.props;
        
        return (
            <nav className="navbar fixed-top ">
                <a className="navbar-brand" href="#" onClick={this.toggleSidenav}><i className="fas fa-bars fa-lg"></i></a>
                <span >
                    <Notificaciones/>
                    <Button bsStyle="link" ><i className="fas fa-user"></i> {usuario.nombre ? ' ' + usuario.nombre + ' ' + usuario.apellidoP + ' ' + (usuario.apellidoM ? usuario.apellidoM : '') : ''}  </Button>
                    <Button bsStyle="link" onClick={this.cerrarSesion}><i className="fas fa-sign-out-alt"></i>  Cerrar sesión</Button>
                </span>
            </nav>
        );
    }
}

Header.defaultProps = {
    signOut: () => {
      console.log('Cerrar sesión');
    },
    fetchGetMe: () => {
        console.log('Obtener info usuario');
      }
  }

export default connect(state =>
     ({ 
         usuario : state.auth.user
     }),{ signOut, fetchGetMe })(Header);


import React, { Component } from 'react';

import ModalAltaVehiculo from './AltaVehiculo';
import { connect } from 'react-redux';
import {
    fetchGetVehiculos,
    fetchAddVehiculo, fetchUpdateVehiculo, fetchDelVehiculo,
    fetchRestoreVehiculo, fetchUpdateStatusVehiculo, fetchHandleModalVehiculo,
    fetchHandleAlertVehiculo, fetchHideAlertVehiculo, fetchGetVehiculoDetalles, fetchRestartVehiculo
} from '../../Reducers/vehiculos';

import { fetchGetTipoVehiculo, fetchGetCatUsuarios} from '../../Reducers/catalogos';
import { fetchHandleAlertMonitor, fetchHideAlertMonitor, fetchSendCommand } from '../../Reducers/monitor';

import ListaVehiculos from './ListaVehiculos';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

class Vehiculos extends Component {
    constructor() {
        super();
        this.state = {
            id_vehiculo: -1,
            accion: -1,
            activeTab1: 'nav-link active',
            activeTab2: 'nav-link',
            activeTab3: 'nav-link',
            activeTab: 1,
            estatus: 'true',
        }

        this.handleTabClick = this.handleTabClick.bind(this);
        this.obtenerVehiculo = this.obtenerVehiculo.bind(this);
        this.agregarVehiculo = this.agregarVehiculo.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleVehiculo = this.handleVehiculo.bind(this);
        this.showAlertMonitor = this.showAlertMonitor.bind(this);
        this.handleStatusVehiculo = this.handleStatusVehiculo.bind(this);
        this.hideAlertaMonitor = this.hideAlertaMonitor.bind(this);
    }

    handleTabClick(event) {
        let index = event.target.id;
        switch (index) {
            case '1':
                this.setState({
                    activeTab1: 'nav-link active',
                    activeTab2: 'nav-link',
                    activeTab3: 'nav-link',
                    activeTab: 1,
                    estatus: 'true'

                });
                break;
            case '0':
                this.setState({
                    activeTab1: 'nav-link',
                    activeTab2: 'nav-link active',
                    activeTab3: 'nav-link',
                    activeTab: 2,
                    estatus: 'false'
                });
                break;
            case '3':
                this.setState({
                    activeTab1: 'nav-link',
                    activeTab2: 'nav-link ',
                    activeTab3: 'nav-link active',
                    activeTab: 3,
                    estatus: ''
                });
                break;
        }
    }

    obtenerVehiculo(id) {
        const { handleModal, getDetalles, getUsuarios} = this.props;
        getUsuarios({activo:'true'});
        getDetalles(id);
        handleModal();
    }

    agregarVehiculo(){      
        const {handleModal, getUsuarios, restartSelected} = this.props;  
        restartSelected();
        getUsuarios({activo:'true'});
        handleModal();
    }

    showAlert(id, tipo) {
        this.props.handleAlert(tipo);
        this.setState({
            id_vehiculo: id,
            accion: tipo
        });
    }

    hideAlert() {
        this.props.hideAlert();
        this.setState({ id_vehiculo: -1, accion: -1 });
    }

    handleVehiculo() {
        const { id_vehiculo, activeTab, accion } = this.state;
        const { updateStatus, desactivar, restore } = this.props;

        if (activeTab === 3) {
            updateStatus(id_vehiculo, accion)
        }
        else {
            if (accion === 1) {
                restore(id_vehiculo);
            }
            else if (accion === 2) {
                desactivar(id_vehiculo);
            }
            else {
                this.hideAlert();
            }
        }
    }

    showAlertMonitor(id, tipo) {
        this.props.handleAlertMonitor(tipo);
        this.setState({
            id_vehiculo: id,
            accion: tipo
        });
    }

    handleStatusVehiculo() {
        const { id_vehiculo, accion } = this.state;
        let datos = [];
        switch (accion) {
            case 1: datos = {
                "comando": "110"
            };
                break;
            case 2:
                datos = {
                    "comando": "109"
                };
                break;
        }
        this.props.sendCommand(datos, id_vehiculo, 'Vehiculos');
    }

    hideAlertaMonitor() {
        this.props.hideAlertMonitor();
        this.setState({ id_vehiculo: -1, accion: -1 });
    }

    render() {
        const { vehiculos, get, add, update, getTiposVehiculos, tipos, handleModal, showModal, alerta, alertaMonitor, vehiculoSeleccionado, usuarios } = this.props;
        const { estatus } = this.state;

        return (
            <div>
                <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText={alerta.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alerta.title} text={alerta.text}
                    onConfirm={this.handleVehiculo} onCancel={this.hideAlert} />

                <SweetAlert show={alertaMonitor.show} type={alertaMonitor.type} confirmButtonText={alertaMonitor.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alertaMonitor.title} text={alertaMonitor.text}
                    onConfirm={this.handleStatusVehiculo} onCancel={this.hideAlertaMonitor} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-10">
                                        <ul className="nav nav-tabs card-header-tabs pull-left" >
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab1} id="1" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>Activos</a>
                                            </li>
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab2} id="0" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>Inactivos</a>
                                            </li>
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab3} id="3" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>General</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2">
                                        <a href="#" className="btn_agregar" onClick={this.agregarVehiculo}><i className="fa fa-plus"></i> Registro</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            <ListaVehiculos showDetails={this.obtenerVehiculo} editState={this.showAlert} cambiarApagado={this.showAlertMonitor} get={get} 
                            estatus = {estatus} vehiculos={vehiculos} />
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && <ModalAltaVehiculo show={showModal} add={add} onHide={showModal?handleModal:''} usuarios = {usuarios}
                    vehiculo={vehiculoSeleccionado} update={update} getTipos={getTiposVehiculos} tiposVehiculos={tipos}  />}
            </div>
        );
    }
}

export default connect(state => ({
    tipos: state.catalogos.tiposVehiculos,
    vehiculos: state.vehiculos.vehiculos,
    showModal: state.vehiculos.showModalVehiculo,
    alerta: state.vehiculos.alertaVehiculo,
    alertaMonitor: state.monitor.alertaMonitor,
    vehiculoSeleccionado : state.vehiculos.vehiculoSeleccionado,
    usuarios : state.catalogos.usuarios
}), {
        get: fetchGetVehiculos,
        getDetalles: fetchGetVehiculoDetalles,
        update: fetchUpdateVehiculo,
        desactivar: fetchDelVehiculo,
        add: fetchAddVehiculo,
        restore: fetchRestoreVehiculo,
        getTiposVehiculos: fetchGetTipoVehiculo,
        updateStatus: fetchUpdateStatusVehiculo,
        handleModal: fetchHandleModalVehiculo,
        handleAlert: fetchHandleAlertVehiculo,
        hideAlert: fetchHideAlertVehiculo,
        handleAlertMonitor: fetchHandleAlertMonitor,
        hideAlertMonitor: fetchHideAlertMonitor,
        sendCommand: fetchSendCommand,
        getUsuarios : fetchGetCatUsuarios,
        restartSelected:fetchRestartVehiculo
    })(Vehiculos);
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Choferes from '../Usuarios/Choferes';
import Monitor from '../Monitor/Monitor';
import Reportes from '../Reportes';
import Supervisores from '../Usuarios/Supervisores';
import Vehiculos from '../Vehiculos/Vehiculos';
import Detalles from '../Vehiculos/Detalles';
import Alertas from '../Alertas/Alertas';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import RestrictedRoute from './RestrictedRoutes';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div className="container-fluid">
                <NotificationContainer />
                <Switch>
                    <Redirect exact={true} from="/" to="/Monitor" />
                    <Route path="/Detalles" component={Detalles} />
                    <Route path="/Alertas" component={Alertas} />
                    <RestrictedRoute path="/Choferes" component={Choferes} nombre={'Choferes'} />
                    <RestrictedRoute path="/Monitor" component={Monitor} nombre={'Monitor'} />
                    <RestrictedRoute path="/Reportes" component={Reportes} nombre={'Reportes'} />
                    <RestrictedRoute path="/Supervisores" component={Supervisores} nombre={'Supervisores'} />
                    <RestrictedRoute path="/Vehiculos" component={Vehiculos} nombre={'Vehiculos'} />
                </Switch>
            </div>
        );
    }
}

export default Content;
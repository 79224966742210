import {
    apiGetAlertas,
    apiDeleteAlertas,
    apiGetHistoricoAlertas
} from '../Api/apiAlertas';
import { NotificationManager } from 'react-notifications';

const initialState = {
    alertas: {
        total: 0,
        rows: []
    },
    historico: {
        total: 0,
        rows: []
    }
};

const GET_ALERTAS = "GET_ALERTAS";
const GET_HISTORIAL = "GET_HISTORIAL";
const DEL_ALERTAS = "DEL_ALERTAS";

const getAlertas = (alertas) => ({ type: GET_ALERTAS, payload: alertas });
const getHistorial = (historial) => ({ type: GET_HISTORIAL, payload: historial });
const delAlertas = () => ({ type: DEL_ALERTAS });

export const fetchGetAlertas = () => {
    return dispatch => {
        apiGetAlertas()
            .then((alertas) => {
                dispatch(getAlertas(alertas));
            })
            .catch((error) => {
                console.log("errorAlertas", error);
                dispatch(getAlertas(initialState.alertas));
            });
    };
}

export const fetchGetHistorico = (obj) => {
    return dispatch => {
        apiGetHistoricoAlertas(obj)
            .then((res) => {
                if (res.status === 200) {
                dispatch(getHistorial(res.body));
                }
                else {
                    NotificationManager.error('Ha ocurrido un error al cargar las notificaciones, por favor intente más tarde.', 'Error');
                    dispatch(getHistorial(initialState.historico));
                }
            })
            .catch((error) => {
                console.log("errorHistorialAlertas", error);
                dispatch(getHistorial(initialState.historico));
            });
    };
}

export const fetchDeleteAlertas = () => {
    return dispatch => {
        apiDeleteAlertas()
            .then((resp) => {
                switch (resp) {
                    case 204:
                        dispatch(delAlertas());
                        break;
                    default:
                        console.log("errorDelAlertas: ", resp);
                        break;
                }
            })
            .catch((error) => {
                console.log("errorDelAlertas", error);
                dispatch(delAlertas());
            });
    };
}

const alertasReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALERTAS:
            return { ...state, alertas: action.payload };
        case GET_HISTORIAL:
            return { ...state, historico: action.payload };
        case DEL_ALERTAS:
            return { ...state, alertas: state.alertas };
        default:
            return { ...state };
    }
};

export default alertasReducer;
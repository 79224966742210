import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Main from './Main'
import moment from 'moment';
import jwtDecode from "jwt-decode";


const PrivateRoutes = () => {
    const { token } = window.localStorage;

    if (token && token !== 'undefined' && token !== undefined) {
        try {
            const currentTime = moment().unix();
            const decoded = jwtDecode(token);
            if (decoded.exp > currentTime) {
                return (
                    <Route path='/' component={Main}></Route>
                );
            }
            else {
                return (<Redirect to="/Login"></Redirect>);
            }
        }
        catch (e) {
            return (<Redirect to="/Login"></Redirect>);
        }
    }
    else {
        return (<Redirect to="/Login"></Redirect>);
    }
};

export default PrivateRoutes;

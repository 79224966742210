import {  combineReducers  } from "redux";
import vehiculosReducer from '../Reducers/vehiculos';
import catalogosReducer from '../Reducers/catalogos';
import monitorReducer from '../Reducers/monitor';
import authReducer from '../Reducers/auth';
import alertasReducer from '../Reducers/alertas';
import historicoReducer from '../Reducers/historico';
import gasolinaReducer from '../Reducers/gasolina';
import usuariosReducer from '../Reducers/usuarios';
import generalReducer from '../Reducers/general';
import horariosReducer from '../Reducers/horarios';

const mainStore = combineReducers(
  {
      vehiculos: vehiculosReducer,
      catalogos:catalogosReducer,
      monitor:monitorReducer,
      auth:authReducer,
      alertas:alertasReducer,
      historico: historicoReducer,
      gasolina: gasolinaReducer,
      usuarios: usuariosReducer,
      general: generalReducer,
      horarios: horariosReducer,
  }
);

export default mainStore;
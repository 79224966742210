import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from '../App';
import Login from '../Components/LogIn/Login';
import Recuperar from '../Components/LogIn/Recuperar';
import Asignar from '../Components/LogIn/Password';
import PrivateRoutes from "./PrivateRoutes";

const AppRoutes = () =>
    <App>
        <Switch>
            <Route path="/Login" component={Login} />
            <Route path="/Recuperar" component={Recuperar} />
            <Route path="/Password/:uuid/" component={Asignar} />            
            <PrivateRoutes></PrivateRoutes>
        </Switch>
    </App>;

 export default AppRoutes;
import React, { Component } from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Select from 'react-select';

import { fetchGetCatVehiculos } from '../Reducers/catalogos';
import { fetchHandleLoading } from '../Reducers/general';
import { baseUrl, GET, CreateRequestReportes, makeUrlGET } from '../Api/general';

import './Shared/loaderCustom.scss';
import download from 'downloadjs';
import { NotificationManager } from 'react-notifications';

let options = []

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "unset",
    background: "transparent",
    borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
    "&:hover": {
      boxShadow: "none"
    },
  }),
  menu: () => ({
    backgroundColor: "white",
    boxShadow: "1px 2px 6px #888888",
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 2,
  }),
  menuList: () => ({
    overflowY: "auto",
    maxHeight: "150px"
  })
};

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

class Reportes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dpStartGral: moment(start),
      dpEndGral: moment(end),
      dpStartVehiculo: moment(start),
      dpEndVehiculo: moment(end),
      fecha_inicial_Gral: moment(start).toISOString(),
      fecha_final_Gral: moment(end).toISOString(),
      fecha_inicial_Vehiculo: moment(start).toISOString(),
      fecha_final_Vehiculo: moment(end).toISOString(),
      id_vehiculo: -1,
      nombreVehiculo: '', 
      selectedOption:null
    };

    this.handleSelectOptions = this.handleSelectOptions.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.resetGral = this.resetGral.bind(this);
    this.imprimirRptGral = this.imprimirRptGral.bind(this);
    this.resetVehiculo = this.resetVehiculo.bind(this);
    this.imprimirRptVehiculo = this.imprimirRptVehiculo.bind(this);
  }

  componentDidMount() {
    this.props.get('');
  }

  handleChangeDTP(name, value) {
    switch (name) {
      case 'fromDateVehiculo':
        this.setState({ dpStartVehiculo: value, fecha_inicial_Vehiculo: moment(value).toISOString(), });
        break;
      case 'toDateVehiculo':
        this.setState({ dpEndVehiculo: value, fecha_final_Vehiculo: moment(value).toISOString() });
        break;
      case 'fromDateGral':
        this.setState({ dpStartGral: value, fecha_inicial_Gral: moment(value).toISOString(), });
        break;
      case 'toDateGral':
        this.setState({ dpEndGral: value, fecha_final_Gral: moment(value).toISOString() });
        break;
      default:
        break;
    }
  };

  handleSelectChange= (selectedOption) => {
    this.setState({
      ...this.state, selectedOption
  });
    if (selectedOption) {
      this.setState({ id_vehiculo: selectedOption.value, nombreVehiculo: selectedOption.label });
    }
    else {
      this.setState({ id_vehiculo: -1, nombreVehiculo: '' });
    }
  }

  handleSelectOptions() {
    options = [];
    this.props.vehiculos.rows.map((vehiculo) =>
      options.push({ value: vehiculo.id, label: vehiculo.nombre })
    );
    return options;
   
  }

  async imprimirRptGral() {
    const { fecha_inicial_Gral, fecha_final_Gral, dpStartGral, dpEndGral } = this.state;
    const { handleLoader } = this.props;
    if (!dpStartGral.isBefore(dpEndGral)) {
      NotificationManager.warning('La fecha final debe ser después de la fecha inicial.', 'Rango de fechas inválido');
    }
    else {
      let titulo = moment(dpStartGral).format("DD/MM/YYYY") + " al " + moment(dpEndGral).format("DD/MM/YYYY");
      handleLoader();
      var request = await CreateRequestReportes(GET);
      let url = baseUrl + 'reportes/?' + makeUrlGET({ fechaInicio: fecha_inicial_Gral, fechaFin: fecha_final_Gral });
      const res = await fetch(url, request).then(res => res.blob());
      download(res, "Reporte general del " + titulo + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      handleLoader();
    }
  }

  resetGral() {
    this.setState({
      dpStartGral: moment(start),
      dpEndGral: moment(end),
      fecha_inicial_Gral: moment(start).toISOString(),
      fecha_final_Gral: moment(end).toISOString(),
    })
  }

  async imprimirRptVehiculo() {
    const { fecha_inicial_Vehiculo, fecha_final_Vehiculo, id_vehiculo, nombreVehiculo, dpStartVehiculo, dpEndVehiculo } = this.state;
    const { handleLoader } = this.props;
    if (!dpStartVehiculo.isBefore(dpEndVehiculo)) {
      NotificationManager.warning('La fecha final debe ser después de la fecha inicial.', 'Rango de fechas inválido');
    }
    else {
    if (id_vehiculo !== -1) {
      let titulo = moment(dpStartVehiculo).format("DD/MM/YYYY") + " al " + moment(dpEndVehiculo).format("DD/MM/YYYY");
      handleLoader();
      var request = await CreateRequestReportes(GET);
      let url = baseUrl + `reportes/${id_vehiculo}/?` + makeUrlGET({ fechaInicio: fecha_inicial_Vehiculo, fechaFin: fecha_final_Vehiculo });
      const res = await fetch(url, request).then(res => res.blob());
      download(res, "Reporte vehiculo:" + nombreVehiculo + " del " + titulo + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      handleLoader();
    }
    else {
      NotificationManager.warning('Para imprimir el reporte debe seleccionar un vehículo.', 'Vehículo no seleccionado');
    }
  }
  }

  resetVehiculo() {
    this.setState({
      dpStartVehiculo: moment(start),
      dpEndVehiculo: moment(end),
      fecha_inicial_Vehiculo: moment(start).toISOString(),
      fecha_final_Vehiculo: moment(end).toISOString(),
      id_vehiculo: -1,
      nombreVehiculo: '', 
      selectedOption:null
    })
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div >
        <div className="row">
          <div className="col-md-12">
            <div className="card" >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <h4> Creación de reportes</h4>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-12">
                    <h4> Reporte general</h4>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="input-group">
                      <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromDateGral" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("fromDateGral", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpStartGral} timeFormat={false} />
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="input-group">
                      <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toDateGral" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("toDateGral", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpEndGral} timeFormat={false} />
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6 col-6">
                    <Button type="button" className="btn btn-raised btn-info" onClick={this.imprimirRptGral}><i className="fas fa-file-alt"></i>&nbsp;&nbsp;Crear reporte</Button>
                  </div>
                  <div className="col-md-6 col-6 text-right">
                    <Button type="button" className="btn btn-raised btn-primary" onClick={this.resetGral}><i className="fas fa-trash"></i>&nbsp;&nbsp;Borrar filtros</Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="card" >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <h4> Reporte vehículo</h4>
                  </div>
                </div>
                <hr />
                <br />
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="input-group">
                      <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromDateVehiculo" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("fromDateVehiculo", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpStartVehiculo} timeFormat={false} />
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="input-group">
                      <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toDateVehiculo" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("toDateVehiculo", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpEndVehiculo} timeFormat={false} />
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <div className="input-group">
                      <span className="input-group-addon" ><i className="fas fa-car"></i></span>&nbsp;&nbsp;&nbsp;
                      <Select options={this.handleSelectOptions()} value={selectedOption} placeholder="- Elegir un vehículo -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectChange} isClearable/>            
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6  col-6">
                    <Button type="button" className="btn btn-raised btn-info" onClick={this.imprimirRptVehiculo}><i className="fas fa-file-alt"></i>&nbsp;&nbsp;Crear reporte</Button>
                  </div>
                  <div className="col-md-6 col-6 text-right">
                    <Button type="button" className="btn btn-raised btn-primary" onClick={this.resetVehiculo}><i className="fas fa-trash"></i>&nbsp;&nbsp;Borrar filtros</Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        
      </div>

    );
  }
}

export default connect(state => ({
  vehiculos: state.catalogos.catVehiculos,
}), {
    get: fetchGetCatVehiculos,
    handleLoader: fetchHandleLoading
  })(Reportes);

import {
    apiGetHorarios,
    apiAddHorario,
    apiGetHorario,
    apiUpdateHorario,
    apiDeleteHorario
} from '../Api/apiHorarios';

import {addHorario, updateHorario, deleteHorario} from './vehiculos'

import { NotificationManager } from 'react-notifications';

const initialState = {
    horarios: {
        rows: [],
        total: 0
    },
    horarioDetalles: []
};

const GET_HORARIOS = "GET_HORARIOS";
//const ADD_HORARIO = "ADD_HORARIO";
const GET_INFO_HORARIO = "GET_INFO_HORARIO";
//const UPDATE_HORARIO = "UPDATE_HORARIO";
//const DEL_HORARIO = "DEL_HORARIO";

const getHorarios = horario => ({ type: GET_HORARIOS, payload: horario });
const getHorario = horario => ({ type: GET_INFO_HORARIO, payload: horario });

export const fetchGetHorarios = (objs) => {
    return dispatch => {
        apiGetHorarios(objs)
            .then((horarios) => {
                dispatch(getHorarios(horarios));
            })
            .catch((error) => {
                console.log("errorGetHorarios", error);
                dispatch(getHorarios(initialState.horarios));
            });
    };
}

export const fetchAddHorario = (body) => {
    return dispatch => {
        apiAddHorario(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addHorario(res.body));
                    /*  NotificationManager.success('Horario guardado correctamente', '¡Éxito!');*/
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(addHorario(initialState.usuarios));
                }
            }).catch((error) => {
                console.log("errorAddHorarios", error);
                dispatch(getHorarios(initialState.horarios));
            });
    };
}

export const fetchGetHorario = (id) => {
    return dispatch => {
        apiGetHorario(id)
            .then((horario) => {
                dispatch(getHorario(horario));
            })
            .catch((error) => {
                console.log("errorHorario", error);
                dispatch(getHorario(initialState.horarioSeleccionado));
            });
    };
}

export const fetchUpdateHorario = (body, id_horario) => {
    return dispatch => {
        apiUpdateHorario(body, id_horario)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateHorario(res.body));
                    /*NotificationManager.success('Horario guardado correctamente', '¡Éxito!');*/
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(updateHorario(initialState.usuarios));
                }
            }).catch((error) => {
                console.log("errorUpdateHorario", error);
                dispatch(updateHorario(initialState.horarios));
            });
    };
}

export const fetchDelHorario = (id,idVehiculo) => {
    return dispatch => {
        apiDeleteHorario(id)
            .then((res) => {
                if(res === '204' || res === 204){
                    dispatch(deleteHorario(id, idVehiculo));
                }
                    
                    /*NotificationManager.success('Horario eliminado correctamente', '¡Éxito!');*/
               
            }).catch((error) => {
                console.log("errorDelHorario", error);
                dispatch(getHorarios(initialState.horarios));
            });
    };
}

const horariosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HORARIOS:
            return { ...state, horarios: action.payload };
       /* case ADD_HORARIO:
            return {
                ...state, vehiculos: {
                    ...state.vehiculos,
                    rows: state.vehiculos.rows.map(vehiculo => {
                        if (vehiculo.id = action.payload.VehiculoId) {
                            vehiculo.horarios = [...vehiculo.horarios, action.payload]
                        }
                    })
                }
            };*/
        case GET_INFO_HORARIO:
            return { ...state, horarioSeleccionado: action.payload };
       /* case UPDATE_HORARIO:
            return {
                ...state, vehiculos: {
                    ...state.vehiculos,
                    rows: state.vehiculos.rows.map(vehiculo => {
                        if (vehiculo.id = action.payload.VehiculoId) {
                            vehiculo.horarios.map(horario => horario.id === action.payload.id ? action.payload : horario)
                        }
                    })
                }
            };
        case DEL_HORARIO:
            return {
                ...state, vehiculos: {
                    ...state.vehiculos,
                    rows: state.vehiculos.rows.map(vehiculo => {
                        if (vehiculo.id = action.idvehiculo) {
                            vehiculo.horarios.filter(elem => elem.id !== action.payload)
                        }
                    })
                }
            };*/
        default:
            return { ...state };
    }
};

export default horariosReducer;
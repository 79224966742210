import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { fetchGetMenu } from '../../Reducers/auth';
import imgChofer from '../../img/wheel.svg';

class Sidebar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.get();
    }

    toggleSidenav() {
        document.getElementById('wrapper').classList.toggle('toggled');
    }

    render() {
        const { menu } = this.props;

        return (
            <div id="sidebar-wrapper">
                <ul className="sidebar-nav">
                    <div className="sidebar_title">

                        <a href="#" onClick={this.toggleSidenav}>Dashboard&nbsp; &nbsp; <i className="fas fa-bars fa-lg"></i></a>
                    </div>
                    {menu.map(item =>
                    item.carpeta === '/Choferes' ?
                        <li key={item.id} className="sidebar-nav-active" >
                        <NavLink to={item.carpeta}>
                        <img className="form-icon" src={imgChofer} alt="" /> &nbsp; &nbsp;
                            {item.menu}
                        </NavLink>
                    </li>
                    :
                        <li key={item.id} className="sidebar-nav-active" >
                            <NavLink to={item.carpeta}>
                                <i className={item.icono}></i> &nbsp; &nbsp;
                                {item.menu}
                            </NavLink>
                        </li>
                    )}                     
                </ul>
            </div>
        );
    }
}

export default withRouter(connect(state => ({
    menu: state.auth.menu,
}), { get: fetchGetMenu })(Sidebar));

import { apiGetTipoVehiculos, apiGetTipoUsuarios } from '../Api/apiCatalogos';
import { apiGetVehiculos } from '../Api/apiVehiculos';
import { apiGetUsuarios } from '../Api/apiUsuarios';

const initialState = {
    tiposVehiculos: {
        total: 0,
        rows: []
    },
    tiposUsuarios: {
        total: 0,
        rows: []
    },
    catVehiculos: {
        rows: [],
        horarios: [],
        total: 0
    },
    usuarios: {
        total: 0,
        rows: []
    }
};

const GET_TIPOS_VEHICULOS = "GET_TIPOS_VEHICULOS";
const GET_TIPOS_USUARIOS = "GET_TIPOS_USUARIOS";
const GET_CAT_VEHICULOS = "GET_CAT_VEHICULOS";
const GET_CAT_USUARIOS = "GET_CAT_USUARIOS";

const getTiposVehiculos = tipos => ({ type: GET_TIPOS_VEHICULOS, payload: tipos });
const getTiposUsuarios = tipos => ({ type: GET_TIPOS_USUARIOS, payload: tipos });
const getCatVehiculos = vehiculos => ({ type: GET_CAT_VEHICULOS, payload: vehiculos });
const getCatUsuarios = usuarios => ({ type: GET_CAT_USUARIOS, payload: usuarios });

export const fetchGetTipoVehiculo = () => {
    return dispatch => {
        apiGetTipoVehiculos()
            .then((tipos_vehiculos) => {
                dispatch(getTiposVehiculos(tipos_vehiculos));
            })
            .catch((error) => {
                console.log("errorGetTipoVehiculos", error);
                dispatch(getTiposVehiculos(initialState.tiposVehiculos));
            });
    };
}

export const fetchGetTipoUsuario = () => {
    return dispatch => {
        apiGetTipoUsuarios()
            .then((tipos_usuarios) => {
                dispatch(getTiposUsuarios(tipos_usuarios));
            })
            .catch((error) => {
                console.log("errorGetTipoUsuarios", error);
                dispatch(getTiposVehiculos(initialState.tiposUsuarios));
            });
    };
}

export const fetchGetCatVehiculos = (obj) => {
    return dispatch => {
        apiGetVehiculos(obj)
            .then((vehiculos) => {
                dispatch(getCatVehiculos(vehiculos));
            })
            .catch((error) => {
                console.log("errorGetCatVehiculos", error);
                dispatch(getCatVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchGetCatUsuarios = (objs) => {
    return dispatch => {
        apiGetUsuarios(objs)
            .then((usuarios) => {
                dispatch(getCatUsuarios(usuarios));
            })
            .catch((error) => {
                console.log("errorGetMe", error);
                dispatch(getCatUsuarios(initialState.usuarios));
            });
    };
}



const catalogosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIPOS_VEHICULOS:
            return { ...state, tiposVehiculos: action.payload };
        case GET_TIPOS_USUARIOS:
            return { ...state, tiposUsuarios: action.payload };
        case GET_CAT_VEHICULOS:
            return { ...state, catVehiculos: action.payload };
        case GET_CAT_USUARIOS:
            return { ...state, usuarios: action.payload };
        default:
            return { ...state };
    }
};


export default catalogosReducer;
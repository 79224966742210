import {
    apiGetVehiculos,
    apiAddVehiculo,
    apiUpdateVehiculo,
    apiRestoreVehiculo,
    apiDelVehiculo,
    apiGetVehiculoUsuarios,
    apiAddVehiculoUsuarios,
    apiDelVehiculosUsuario,
    apiGetGasolinaVehiculo
} from '../Api/apiVehiculos';
import { NotificationManager } from 'react-notifications';
import { fetchAddHorario, fetchUpdateHorario, fetchDelHorario } from './horarios';

const initialState = {
    vehiculos: {
        rows: [],
        horarios: [],
        total: 0,
        usuarios: {
            choferes: [],
            supervisores: []
        }
    },
    vehiculoSeleccionado: {
        horarios: [],
        tipo: [],
        usuarios: {
            choferes: [],
            supervisores: []
        }
    },
    vehiculoUsuarios: {
        usuarios: {
            choferes: [],
            supervisores: [],
        }
    },
    vehiculoGasolina: {
        rows: [],
        total: 0
    },
    showModalVehiculo: false,
    alertaVehiculo: {
        show: false,
        type: 'warning',
        title: '',
        text: '',
        confirmButtonText: '',
    },
    vista: 'Vehiculos'
};

const GET_VEHICULOS = "GET_VEHICULOS";
const ADD_VEHICULO = "ADD_VEHICULO";
const GET_INFO_VEHICULO = "GET_INFO_VEHICULO";
const UPDATE_VEHICULO_DETALLES = "UPDATE_VEHICULO_DETALLES";
const UPDATE_VEHICULO = "UPDATE_VEHICULO";
const RESTORE_VEHICULO = "RESTORE_VEHICULO";
const DEL_VEHICULO = "DEL_VEHICULO";
const GET_VEHICULO_USUARIOS = "GET_VEHICULO_USUARIOS";
const ADD_VEHICULO_USUARIOS = "ADD_VEHICULO_USUARIOS";
const DEL_VEHICULO_USUARIOS = "DEL_VEHICULO_USUARIOS";
const GET_VEHICULO_GASOLINA = "GET_VEHICULO_GASOLINA";
const SEND_COMMAND_VEHICULO = "SEND_COMMAND_VEHICULO";
const SEND_COMMAND_DETALLES = "SEND_COMMAND_DETALLES";
const ADD_HORARIO = "ADD_HORARIO";
const UPDATE_HORARIO = "UPDATE_HORARIO";
const DEL_HORARIO = "DEL_HORARIO";
const SET_VISTA = "SET_VISTA";

const SHOW_MODAL_VEHICULO = "SHOW_MODAL_VEHICULO";
const HANDLE_ALERT_VEHICULO = "HANDLE_ALERT_VEHICULO";

const getVehiculos = vehiculo => ({ type: GET_VEHICULOS, payload: vehiculo });
const addVehiculo = vehiculo => ({ type: ADD_VEHICULO, payload: vehiculo });
const getVehiculoDetalles = vehiculo => ({ type: GET_INFO_VEHICULO, payload: vehiculo });
const updateVehiculoDetalles = vehiculo => ({ type: UPDATE_VEHICULO_DETALLES, payload: vehiculo });
const updateVehiculo = data => ({ type: UPDATE_VEHICULO, payload: data });
const restoreVehiculo = id => ({ type: RESTORE_VEHICULO, payload: id });
const delVehiculo = id => ({ type: DEL_VEHICULO, payload: id });
const getVehiculoUsuarios = detallesVehiculo => ({ type: GET_VEHICULO_USUARIOS, payload: detallesVehiculo });
const addVehiculoUsuarios = vehiculo => ({ type: ADD_VEHICULO_USUARIOS, payload: vehiculo });
const delVehiculoUsuarios = vehiculo => ({ type: DEL_VEHICULO_USUARIOS, payload: vehiculo });
const getVehiculoGasolina = gasolina => ({ type: GET_VEHICULO_GASOLINA, payload: gasolina });
export const sendCommandVehiculo = (idVehiculo) => ({ type: SEND_COMMAND_VEHICULO, payload: idVehiculo });
export const sendCommandDetalles = (idVehiculo) => ({ type: SEND_COMMAND_DETALLES, payload: idVehiculo });
export const addHorario = horario => ({ type: ADD_HORARIO, payload: horario, });
export const updateHorario = data => ({ type: UPDATE_HORARIO, payload: data });
export const deleteHorario = (id, idvehiculo) => ({ type: DEL_HORARIO, payload: id, vehiculo: idvehiculo });
const setVista = vista => ({ type: SET_VISTA, payload: vista })

const handleModalVehiculo = () => ({ type: SHOW_MODAL_VEHICULO });
const handleAlertVehiculo = (alert) => ({ type: HANDLE_ALERT_VEHICULO, payload: alert });

export const fetchGetVehiculos = (obj) => {
    return dispatch => {
        apiGetVehiculos(obj)
            .then((vehiculos) => {
                dispatch(getVehiculos(vehiculos));
            })
            .catch((error) => {
                console.log("errorGetVehiculos", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchAddVehiculo = (body, post_horarios, put_usuarios) => {
    return dispatch => {
        apiAddVehiculo(body)
            .then((res) => {
                dispatch(setVista('Vehiculos'));
                if (res.status === 201) {
                    dispatch(addVehiculo(res.body));
                    if (post_horarios.dias.length > 0) {
                        post_horarios.vehiculoId = res.body.id;
                        dispatch(fetchAddHorario(post_horarios));
                    }
                    if (put_usuarios.ids.length > 0) {
                        dispatch(fetchAddUsuarios(res.body.id, put_usuarios));
                    }
                    dispatch(handleModalVehiculo());
                    dispatch(getVehiculoDetalles(initialState.vehiculoSeleccionado));
                    NotificationManager.success('Vehículo guardado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
            }).catch((error) => {
                console.log("errorAddVehiculos", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchGetVehiculoDetalles = (id) => {
    return dispatch => {
        apiGetVehiculoUsuarios(id)
            .then((vehiculo) => {
                dispatch(getVehiculoDetalles(vehiculo));
            })
            .catch((error) => {
                console.log("errorVehiculo", error);
                dispatch(getVehiculoDetalles(initialState.vehiculoSeleccionado));
            });
    };
}

export const fetchUpdateVehiculoDetalles = (body, id_vehiculo, post_horarios, put_horarios, delete_horarios, put_usuarios, del_usuarios) => {
    return dispatch => {
        apiUpdateVehiculo(body, id_vehiculo)
            .then((res) => {
                dispatch(setVista('Detalles'));
                if (res.status === 200) {
                    dispatch(fetchGetVehiculoDetalles(id_vehiculo));
                    if (post_horarios.dias.length > 0) {
                        dispatch(fetchAddHorario(post_horarios));
                    }
                    if (put_horarios.length > 0) {
                        put_horarios.map(horario => {
                            dispatch(fetchUpdateHorario(horario.body, horario.idParam));
                        });
                    }
                    if (delete_horarios.length > 0) {
                        delete_horarios.map(horario => {
                            dispatch(fetchDelHorario(horario, id_vehiculo));
                        });
                    }
                    if (put_usuarios.ids.length > 0) {
                        dispatch(fetchAddUsuarios(id_vehiculo, put_usuarios));
                    }
                    if (del_usuarios.ids.length > 0) {
                        dispatch(fetchDelUsuarios(id_vehiculo, del_usuarios));
                    }
                    dispatch(handleModalVehiculo());
                    NotificationManager.success('Vehículo guardado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
            }).catch((error) => {
                console.log("errorUpdateVehiculoDetalles", error);
                dispatch(updateVehiculoDetalles(initialState.vehiculoSeleccionado));
            });
    };
}

export const fetchUpdateVehiculo = (body, id_vehiculo, post_horarios, put_horarios, delete_horarios, put_usuarios, del_usuarios) => {
    return dispatch => {
        apiUpdateVehiculo(body, id_vehiculo)
            .then((res) => {
                dispatch(setVista('Vehiculos'));
                if (res.status === 200) {
                    dispatch(updateVehiculo(res.body));
                    if (post_horarios.dias.length > 0) {
                        dispatch(fetchAddHorario(post_horarios));
                    }
                    if (put_horarios.length > 0) {
                        put_horarios.map(horario => {
                            dispatch(fetchUpdateHorario(horario.body, horario.idParam));
                        });
                    }
                    if (delete_horarios.length > 0) {
                        delete_horarios.map(horario => {
                            dispatch(fetchDelHorario(horario, id_vehiculo));
                        });
                    }
                    if (put_usuarios.ids.length > 0) {
                        dispatch(fetchAddUsuarios(id_vehiculo, put_usuarios));
                    }
                    if (del_usuarios.ids.length > 0) {
                        dispatch(fetchDelUsuarios(id_vehiculo, del_usuarios));
                    }
                    dispatch(handleModalVehiculo());
                    dispatch(getVehiculoDetalles(initialState.vehiculoSeleccionado));
                    NotificationManager.success('Vehículo guardado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
            }).catch((error) => {
                console.log("errorUpdateVehiculo", error);
                dispatch(updateVehiculo(initialState.vehiculos));
            });
    };
}

export const fetchRestoreVehiculo = (id) => {
    return dispatch => {
        apiRestoreVehiculo(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(restoreVehiculo(id));
                    NotificationManager.success('Vehículo activado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
            }).catch((error) => {
                console.log("errorRestoreVehiculo", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchDelVehiculo = (id) => {
    return dispatch => {
        apiDelVehiculo(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(delVehiculo(id));
                    NotificationManager.success('Vehículo desactivado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
            }).catch((error) => {
                console.log("errorDelVehiculo", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchUpdateStatusVehiculo = (id, status) => {
    switch (status) {
        case 1:
            return dispatch => {
                apiRestoreVehiculo(id)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(updateVehiculo(res.body));
                            NotificationManager.success('Vehículo activado correctamente', '¡Éxito!');
                        }
                        else {
                            NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                        }
                        dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
                    }).catch((error) => {
                        console.log("errorRestoreVehiculo", error);
                        dispatch(getVehiculos(initialState.vehiculos));
                    });
            };
        case 2:
            return dispatch => {
                apiDelVehiculo(id)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(updateVehiculo(res.body));
                            NotificationManager.success('Vehículo desactivado correctamente', '¡Éxito!');
                        }
                        else {
                            NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                        }
                        dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
                    }).catch((error) => {
                        console.log("errorDelVehiculo", error);
                        dispatch(getVehiculos(initialState.vehiculos));
                    });
            };

    };
}

export const fetchGetUsuarios = (id) => {
    return dispatch => {
        apiGetVehiculoUsuarios(id)
            .then((vehiculo) => {
                dispatch(getVehiculoUsuarios(vehiculo));
            })
            .catch((error) => {
                console.log("errorGetVehiculoUsuarios", error);
                dispatch(getVehiculos(initialState.vehiculoSeleccionado));
            });
    };
}

export const fetchAddUsuarios = (id, usuarios) => {
    return dispatch => {
        apiAddVehiculoUsuarios(id, usuarios)
            .then((vehiculo) => {
                dispatch(addVehiculoUsuarios(vehiculo));
            })
            .catch((error) => {
                console.log("errorAddVehiculoUsuarios", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchDelUsuarios = (id, usuarios) => {
    return dispatch => {
        apiDelVehiculosUsuario(id, usuarios)
            .then((vehiculo) => {
                dispatch(delVehiculoUsuarios(vehiculo));
            })
            .catch((error) => {
                console.log("errorDelVehiculoUsuarios", error);
                dispatch(getVehiculos(initialState.vehiculos));
            });
    };
}

export const fetchGetGasolina = (id, params) => {
    return dispatch => {
        apiGetGasolinaVehiculo(id, params)
            .then((gasolina) => {
                dispatch(getVehiculoGasolina(gasolina));
            })
            .catch((error) => {
                console.log("errorGetVehiculoGasolina", error);
                dispatch(getVehiculoGasolina(initialState.vehiculoGasolina));
            });
    };
}

export const fetchHandleModalVehiculo = () => {
    return dispatch => {
        dispatch(handleModalVehiculo());
    }
}

export const fetchHandleAlertVehiculo = (tipo) => {
    switch (tipo) {
        case 1:
            return dispatch => {
                dispatch(handleAlertVehiculo({
                    show: true,
                    type: 'warning',
                    title: 'Activar vehículo',
                    text: '¿Está seguro que desea activar el vehículo seleccionado?',
                    confirmButtonText: 'Activar',
                }));
            };
        case 2:
            return dispatch => {
                dispatch(handleAlertVehiculo({
                    show: true,
                    type: 'warning',
                    title: 'Eliminar vehículo',
                    text: '¿Está seguro que desea eliminar el vehículo seleccionado?',
                    confirmButtonText: 'Eliminar',
                }));
            };
        default:
            return dispatch => {
                dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
            };
    };
}

export const fetchHideAlertVehiculo = () => {
    return dispatch => {
        dispatch(handleAlertVehiculo(initialState.alertaVehiculo));
    }
}

export const fetchRestartVehiculo = () => {
    return dispatch => {
        dispatch(getVehiculoDetalles(initialState.vehiculoSeleccionado));
    }
}

const vehiculosReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISTA:
            return { ...state, vista: action.payload };
        case GET_VEHICULOS:
            return { ...state, vehiculos: { ...state.vehiculos, ...action.payload } };
        case ADD_VEHICULO:
            return { ...state, vehiculos: { ...state.vehiculos, total: state.vehiculos.total + 1, rows: [...state.vehiculos.rows, action.payload] } };
        case GET_INFO_VEHICULO:
            return { ...state, vehiculoSeleccionado: action.payload };
        case UPDATE_VEHICULO_DETALLES:
            return { ...state, vehiculoSeleccionado: action.payload };
        case UPDATE_VEHICULO:
            return { ...state, vehiculos: { ...state.vehiculos, total: state.vehiculos.total, rows: state.vehiculos.rows.map(vehiculo => vehiculo.id === action.payload.id ? action.payload : vehiculo) } };
        case RESTORE_VEHICULO:
            return { ...state, vehiculos: { ...state.vehiculos, total: state.vehiculos.total - 1, rows: state.vehiculos.rows.filter(elem => elem.id !== action.payload) } };
        case DEL_VEHICULO:
            return { ...state, vehiculos: { ...state.vehiculos, total: state.vehiculos.total - 1, rows: state.vehiculos.rows.filter(elem => elem.id !== action.payload) } };
        case GET_VEHICULO_USUARIOS:
            return { ...state, vehiculoUsuarios: action.payload };
        case ADD_VEHICULO_USUARIOS:
            return { ...state, vehiculoSeleccionado: action.payload };
        case DEL_VEHICULO_USUARIOS:
            return { ...state, vehiculoSeleccionado: action.payload };
        case GET_VEHICULO_GASOLINA:
            return { ...state, vehiculoGasolina: action.payload };
        case SHOW_MODAL_VEHICULO:
                return { ...state, showModalVehiculo: !state.showModalVehiculo };
        case HANDLE_ALERT_VEHICULO:
            return { ...state, alertaVehiculo: action.payload };
        case SEND_COMMAND_VEHICULO:
            return {
                ...state, vehiculos: {
                    ...state.vehiculos,
                    rows: state.vehiculos.rows.map(vehiculo => vehiculo.id === action.payload ? { ...vehiculo, apagado: !vehiculo.apagado } : vehiculo)
                }
            };
        case SEND_COMMAND_DETALLES:
            return {
                ...state, vehiculoSeleccionado: {
                    ...state.vehiculoSeleccionado, apagado: !state.vehiculoSeleccionado.apagado
                }
            };
        case ADD_HORARIO:
            if (state.vista === 'Vehiculos') {
                return {
                    ...state, vehiculos: {
                        ...state.vehiculos,
                        rows: state.vehiculos.rows.map(vehiculo => vehiculo.id === action.payload[0].VehiculoId ?
                            { ...vehiculo, horarios: [...vehiculo.horarios, ...action.payload] } : vehiculo)
                    }
                };
            }
            else {
                return {
                    ...state, vehiculoSeleccionado: {
                        ...state.vehiculoSeleccionado,
                        horarios: [...state.vehiculoSeleccionado.horarios, ...action.payload]
                    }
                };
            }

        case UPDATE_HORARIO:
            if (state.vista === 'Vehiculos') {
                return {
                    ...state, vehiculos: {
                        ...state.vehiculos,
                        rows: state.vehiculos.rows.map(vehiculo =>
                            vehiculo.id === action.payload.VehiculoId ?
                                {
                                    ...vehiculo, horarios: vehiculo.horarios.map(horario =>
                                        horario.id === action.payload.id ?
                                            action.payload
                                            : horario)
                                }
                                : vehiculo
                        )
                    }
                };
            }
            else {
                return {
                    ...state, vehiculoSeleccionado: {
                        ...state.vehiculoSeleccionado,
                        horarios: state.vehiculoSeleccionado.horarios.map(horario => horario.id === action.payload.id ? action.payload : horario)
                    }
                };
            }
        case DEL_HORARIO:
            if (state.vista === 'Vehiculos') {
                return {
                    ...state, vehiculos: {
                        ...state.vehiculos,
                        rows: state.vehiculos.rows.map(vehiculo =>
                            vehiculo.id === action.vehiculo ?
                                {
                                    ...vehiculo, horarios: vehiculo.horarios.filter(horario =>
                                        horario.id !== action.payload)
                                }
                                : vehiculo
                        )
                    }
                };
            }
            else {
                return {
                    ...state, vehiculoSeleccionado: {
                        ...state.vehiculoSeleccionado,
                        horarios: state.vehiculoSeleccionado.horarios.filter(horario => horario.id !== action.payload)
                    }
                };
            }
        default:
            return { ...state };
    }
};

export default vehiculosReducer;
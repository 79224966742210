import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchGetGasolina } from '../../Reducers/historico';
import moment from 'moment';

class TotalesGasolina extends Component {
    constructor(props) {
        super(props);        
        this.updateTotales = this.updateTotales.bind(this);
    }

    componentDidMount() {
        const { inicio, fin, vehiculo, getHistorico } = this.props;        
        getHistorico(vehiculo, inicio, fin);
    }

    updateTotales() {
        const { inicio, fin, vehiculo, getHistorico } = this.props;
        getHistorico(vehiculo, inicio, fin);
    }

    render() {
        const { historicoGasolina } = this.props;
        
        return (
            <div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Table responsive id="tbl_totales">
                            <thead>
                                <tr>
                                    <th rowSpan='2'>Fecha</th>
                                    <th rowSpan='2'>Distancia</th>
                                    <th colSpan='2'>Ingreso</th>
                                    <th colSpan='2'>Gasto estimado</th>
                                </tr>
                                <tr>
                                    <th>Ingreso</th>
                                    <th>Costo p/litro</th>
                                    <th>Litros</th>
                                    <th>Costo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historicoGasolina.dias.length > 0
                                ?
                                    historicoGasolina.dias.map((item, index) =>
                                    <tr key={index}>
                                        <td>{moment(item.fecha).format('DD/MM/YYYY')}</td>
                                        <td>{Number((item.distancia).toFixed(3))} km</td>
                                        <td>$ {item.ingresos ? item.ingresos.ingreso : "0.00"}</td>
                                        <td>$ {item.ingresos ? item.ingresos.costo : "0.00"}</td>
                                        <td>{item.gasolina ? Number((item.gasolina.litros).toFixed(3)) : 0} lt</td>
                                        <td>$ {item.gasolina ? Number((item.gasolina.costo).toFixed(3)) : "0.00"}</td>
                                    </tr>                                
                                )
                                :
                                <tr><td colSpan="6">No se encontraron registros</td></tr>
                            }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{Number((historicoGasolina.distancia).toFixed(3))} km</td>
                                    <td>$ {historicoGasolina.total? historicoGasolina.total : "0.00"}</td>
                                    <td></td>
                                    <td>{historicoGasolina.gasolina ? Number((historicoGasolina.gasolina.litros).toFixed(3)) : 0} lt</td>
                                    <td>$ {historicoGasolina.gasolina ? Number((historicoGasolina.gasolina.costo).toFixed(3)) : "0.00"}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(state => ({
    historicoGasolina: state.historico.gasolinaVehiculo,
}), { getHistorico: fetchGetGasolina }, null, { withRef: true })(TotalesGasolina);

import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import moment from 'moment';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class AcordionParadas extends Component {
    constructor() {
        super()
        this.state = {
            arregloHorario: [{ horario: '12:00 AM - 04:00 AM', inicio: '00:00', fin: '4:00' },
            { horario: '04:00 AM - 08:00 AM', inicio: '04:00', fin: '8:00' },
            { horario: '08:00 AM - 12:00 PM', inicio: '08:00', fin: '12:00' },
            { horario: '12:00 PM - 04:00 PM', inicio: '12:00', fin: '16:00' },
            { horario: '04:00 PM - 08:00 PM', inicio: '16:00', fin: '20:00' },
            { horario: '08:00 PM - 12:00 AM', inicio: '20:00', fin: '23:59' }
            ],
        };
    }

    paradasTabla(paradas, fecha, horarios) {
        let paradashorario = [];
        paradas.map((item) => {
            let inicio = moment(fecha + horarios.inicio, 'DD/MM/YYYY HH:mm');
            let fin = moment(fecha + horarios.fin, 'DD/MM/YYYY HH:mm');
            let fecha_gps = moment(moment(item.timestamp_inicio).format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm');
            if (inicio.isBefore(fecha_gps) && fecha_gps.isBefore(fin)) {
                paradashorario.push(
                    {
                        direccion: item.direccion,
                        fecha: moment(item.timestamp_inicio).format("DD/MM/YYYY HH:mm"),
                        duracion: 'Duración: ' + item.tiempo,
                        opciones: <Button type="button" className="btn btn-raised btn-info" onClick={() => window.open("http://www.google.com/maps/place/" + item.lat + "," + item.lng, "_blank")}><i className="fas fa-map-marker-alt"></i> Ver en mapa</Button>
                    }
                );
            }
        })
        return paradashorario;
    }

    stopArray(fechas, paradas) {
        const { arregloHorario } = this.state;
        let arregloacordion = [];
        fechas.map((fecha) => {
            arregloHorario.map((horarios) => {
                arregloacordion.push(
                    {
                        titulo: fecha.dia + horarios.horario,
                        tabla: this.paradasTabla(paradas, fecha.dia, horarios)
                    }
                )
            })
        });
        return arregloacordion;
    }

    render() {
        const { fechas, paradas } = this.props;
        const acordion = this.stopArray(fechas, paradas.rows);
      
        return (
            <div>
                <br />     
                <div className="row">
                    <div className="col-md-12">
                        {paradas.total > 0
                            ?
                            <Accordion accordion={true} key="1">
                                {acordion.map((punto, i) =>
                                    (punto.tabla.length > 0 ?
                                        <AccordionItem key={i}>
                                            <AccordionItemTitle>
                                                <h5 className="u-position-relative">
                                                    {punto.titulo}
                                                    <div className="accordion__arrow" role="presentation" />
                                                </h5>
                                            </AccordionItemTitle>
                                            <AccordionItemBody>
                                                <Table responsive striped >
                                                    <tbody>
                                                        {punto.tabla.map((item, x) =>
                                                            <tr key={x}>
                                                                <td>{item.direccion}</td>
                                                                <td>{item.fecha} </td>
                                                                <td>{item.duracion}</td>
                                                                <td> {item.opciones}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </AccordionItemBody>
                                        </AccordionItem>
                                        :
                                        ''
                                    )
                                )
                                }
                            </Accordion>
                            :
                            <div className="text-center">
                                <p>No se encontraron registros</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AcordionParadas;
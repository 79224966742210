import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetVehiculos = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'vehiculos/?'+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiAddVehiculo = async (body) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'vehiculos/';
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetVehiculo = async ( id_vehiculo) =>{
    let request = await CreateRequest(GET);
    let url = baseUrl + `vehiculos/${id_vehiculo}/`;
    return fetch(url, request).then(res => res.json());
};

export const apiUpdateVehiculo = async (body, id_vehiculo) =>{
    let request = await CreateRequest(PUT, body);
    let url = baseUrl + `vehiculos/${id_vehiculo}/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiRestoreVehiculo = async (id_vehiculo) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl + `vehiculos/${id_vehiculo}/activar/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiDelVehiculo = async (id_vehiculo) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl +  `vehiculos/${id_vehiculo}/desactivar/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetVehiculoUsuarios = async (id_vehiculo) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl +  `vehiculos/${id_vehiculo}/usuarios/`;
    return fetch(url, request).then(res => res.json());
};

export const apiAddVehiculoUsuarios = async (id_vehiculo, objs) =>{
    var request = await CreateRequest(PUT, objs);
    let url = baseUrl + `vehiculos/${id_vehiculo}/usuarios/`;
    return fetch(url, request).then(res => res.json());
};

export const apiDelVehiculosUsuario = async (id_vehiculo, objs) =>{
    var request = await CreateRequest(DELETE, objs);
    let url = baseUrl + `vehiculos/${id_vehiculo}/usuarios/`;
    return fetch(url, request).then(res => res.json());
};

export const apiGetGasolinaVehiculo = async (id_vehiculo, objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `vehiculos/${id_vehiculo}/gasolina/?`+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

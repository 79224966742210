import L from 'leaflet';

const iconCarVerde = new L.Icon({
    iconUrl: require('../../img/PinCarroVerde.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const iconCarGris = new L.Icon({
    iconUrl: require('../../img/PinCarroGris.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const iconCarRojo = new L.Icon({
    iconUrl: require('../../img/PinCarroRojo.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const iconMotoRoja= new L.Icon({
    iconUrl: require('../../img/PinMotoRoja.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const iconMotoVerde = new L.Icon({
    iconUrl: require('../../img/PinMotoVerde.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const iconMotoGris = new L.Icon({
    iconUrl: require('../../img/PinMotoGris.png'),
    iconSize:     [40, 45], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});


const iconStop = new L.Icon({
    iconUrl: require('../../img/marker-dot.png'),
    iconSize:     [20,20], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [10, 20], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -20] // point from which the popup should open relative to the iconAnchor
});

const iconAlert = new L.Icon({
    iconUrl: require('../../img/pin_alerta.png'),
    iconSize:     [25,25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [12, 25], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
});

const iconStart = new L.Icon({
    iconUrl: require('../../img/pin_inicio.png'),
    iconSize:     [25,25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [12, 25], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
});

const iconEnd = new L.Icon({
    iconUrl: require('../../img/pin_fin.png'),
    iconSize:     [25,25], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [12, 25], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
});


export { iconCarVerde, iconCarGris, iconCarRojo, iconMotoVerde, iconMotoGris, iconMotoRoja, iconStop, iconStart, iconEnd, iconAlert   };
import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetTipoVehiculos = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'tipos/vehiculos/';
    return fetch(url, request).then(res => res.json());
};

export const apiGetTipoUsuarios = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'tipos/usuarios/';
    return fetch(url, request).then(res => res.json());
};
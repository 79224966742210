import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetParadas = async (objs, id) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `historico/paradas/${id}/?` + makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};

export const apiGetHistorico = async (objs, id) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `historico/${id}/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiGetGasolina= async (id, objs) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `historico/gasolina/${id}/?` + makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};

export const apiGetKilometros= async (id, objs) =>{
    var request = await  CreateRequest(GET);
    let url = baseUrl + `historico/kilometros/${id}/?` + makeUrlGET(objs) ;
    return fetch(url, request).then(res => res.json());
};



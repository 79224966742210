import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, Polyline, Tooltip } from 'react-leaflet';
import Leaflet from 'leaflet';
import { iconStop, iconStart, iconEnd } from './Icon';
import moment from 'moment';

let position;
let zoomMap;

export default class mapaDetalles extends Component {
  constructor() {
    super();
    this.state = {
      lat: 29.0667,
      lng: -110.9667,
      zoom: 11,
    };
  }

  componentDidMount() {
    this.map = this.mapInstance.leafletElement;
  }

  componentWillUnmount() {
    this.props.resetInfo();
  }

  resetCenter() {
    const { lat, lng, zoom } = this.state;
    const { coordenadas } = this.props;

    if (coordenadas !== undefined && coordenadas.length > 0) {
      this.findBounds();
    }
    else {
      position = [lat, lng];
      this.map.flyTo(position, zoom)
    }
  }

  findBounds() {
    const { coordenadas } = this.props;
    let mapBounds = Leaflet.latLngBounds(coordenadas);
    this.map.flyToBounds(mapBounds)
  }

  render() {
    const { coordenadas, paradas } = this.props;
    const { lat, lng, zoom } = this.state;

    position = [lat, lng];
    zoomMap = zoom;

    if (coordenadas !== undefined && coordenadas.length > 0) {
      this.findBounds();
    }
    else {
      position = [lat, lng];
      zoomMap = zoom;
    }

    return (
      <Map id="cont_mapa_detalles" ref={e => { this.mapInstance = e }} center={position} zoom={zoomMap} >
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polyline positions={coordenadas} />

        {coordenadas.map((v, i) =>
          (i === 0 ?
            <Marker key={v.id} position={[v.lat, v.lng]} icon={iconStart} >
              <Popup>
                <a onClick={() => window.open("http://www.google.com/maps/place/" + v.lat + "," + v.lng, "_blank")}>
                  Hora: {moment(v.createdAt).format("DD/MM/YYYY HH:mm")} <br />
                </a>
              </Popup>
            </Marker>
            :
            (i === (coordenadas.length - 1) ?
              <Marker key={v.id} position={[v.lat, v.lng]} icon={iconEnd}  >
                <Popup>
                  <a onClick={() => window.open("http://www.google.com/maps/place/" + v.lat + "," + v.lng, "_blank")}>
                    Hora: {moment(v.createdAt).format("DD/MM/YYYY HH:mm")} <br />
                  </a>
                </Popup>
              </Marker>
              :
              ''
            )
          )
        )}

        {paradas.rows.map((marker, index) =>
          <Marker key={index} position={[marker.lat, marker.lng]} icon={iconStop}>
            <Popup>
              <a onClick={() => window.open("http://www.google.com/maps/place/" + marker.lat + "," + marker.lng, "_blank")}>
                Hora de inicio: {moment(marker.timestamp_inicio).format("DD/MM/YYYY HH:mm")} <br />
                Hora de fin: {moment(marker.timestamp_fin).format("DD/MM/YYYY HH:mm")}  <br />
                Tiempo en el punto: {marker.tiempo}
              </a>
            </Popup>
          </Marker>
        )}
      </Map>
    )
  }
}
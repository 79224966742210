import React, { Component } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { Link } from "react-router-dom";
import AcordionParadas from '../Vehiculos/Paradas';

import 'react-accessible-accordion/dist/fancy-example.css';

let start = new Date();

class Detalles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechas: [
                { dia: moment(start).format('DD/MM/YYYY') }
            ],
        };
    }

    diaSemana(numdia) {
        let dia = '';
        switch (numdia) {
            case 1:
                dia = 'Lunes';
                break;
            case 2:
                dia = 'Martes';
                break;
            case 3:
                dia = 'Miércoles';
                break;
            case 4:
                dia = 'Jueves';
                break;
            case 5:
                dia = 'Viernes';
                break;
            case 6:
                dia = 'Sábado';
                break;
            case 0:
                dia = 'Domingo';
                break;
        }
        return (dia);
    }

    render() {
        const { paradas, vehiculo, permisos, cambiarApagadoDetalles } = this.props;

        return (
            <Modal {...this.props} bsSize='sm' container={this} aria-labelledby="contained-modal-title" >
                <Modal.Header closeButton>
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <b>Vehiculo:</b> {vehiculo.vehiculo ? vehiculo.vehiculo.nombre : ''}
                        </div>
                        <div className="col-md-2 col-12">
                            <div className="text-center"> {vehiculo.estado ? vehiculo.estado === 1 ? 'Activo' : 'Inactivo' : ''} </div>
                        </div>

                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <div className="row">
                            <div className="col-md-3 col-3">
                                <b>Chofer:</b>
                            </div>
                            <div className="col-md-9 col-9">
                                <div className="float-right">
                                    {vehiculo.vehiculo ?
                                        vehiculo.vehiculo.usuarios.choferes.map((usuario, index) =>
                                            index > 0 ? ', ' + usuario.nombre + ' ' + usuario.apellidoP + ' ' + (usuario.apellidoM ? usuario.apellidoM : '') :
                                                usuario.nombre + ' ' + usuario.apellidoP + ' ' + (usuario.apellidoM ? usuario.apellidoM : '')
                                        )
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <b>Fecha de inicio:</b>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="float-right">{moment(this.state.startDate).format('DD/MM/YYYY')}</div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6 col-6">
                                <b>Fecha final:</b>
                            </div>
                            <div className="col-md-6 col-6">
                                <div className="float-right">{moment(this.state.startDate).format('DD/MM/YYYY')}</div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <b>Horario de trabajo:</b>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                {vehiculo.vehiculo ?
                                    vehiculo.vehiculo.horarios.map((horario, index) =>
                                        <p key={index}>{this.diaSemana(horario.dia)} de {horario.entrada.slice(0, 5)} a  {horario.salida.slice(0, 5)}</p>
                                    )
                                    : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12 text-center">
                                <Link className="btn btn-raised btn-primary" to={{ pathname: '/Detalles', state: { id_vehiculo: (vehiculo.vehiculo ? vehiculo.vehiculo.id : -1) } }}>Ver más</Link>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 col-12">
                                Paradas
                            </div>
                        </div><br />
                        <AcordionParadas fechas={this.state.fechas} paradas={paradas} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {permisos === 1 && vehiculo.vehiculo && <Button className={vehiculo.vehiculo.apagado ? "btn btn-raised btn-success btn-lg btn-block"  : "btn btn-raised btn-danger btn-lg btn-block" } onClick={() => cambiarApagadoDetalles(vehiculo.vehiculo.id, (vehiculo.vehiculo.apagado ? 1 : 2))} >{vehiculo.vehiculo.apagado ? 'Encender' : 'Apagar'}</Button>}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Detalles;
import {
    apiHandleAuth,
    apiGetMenu,
    apiChangePassword,
    apiEditPassword
} from '../Api/apiAuth';

import {
    apiGetMe
} from '../Api/apiUsuarios';

import {
    handleLoading
} from './general'

const initState = {
    user: {
        token: '',
        tipo: {}
    },
    menu: [],
    alerta: {
        show: false,
        type: 'info',
        title: '',
        text: ''
    }
}

const SIGN_IN_USER = 'SIGN_IN_USER';
const SIGN_OUT_USER = 'SIGN_OUT_USER';
const GET_MENU = 'GET_MENU';
const SHOW_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';

const signInUser = (user) => ({ type: SIGN_IN_USER, payload: user });
const signOutUser = () => ({ type: SIGN_OUT_USER });
const getMenu = (menu) => ({ type: GET_MENU, payload: menu });
const showAlert = (mensaje) => ({ type: SHOW_ALERT, payload: mensaje });
const hideAlert = () => ({ type: HIDE_ALERT });

export const signIn = (correo, password) => {
    return dispatch => {
        dispatch(handleLoading());
        apiHandleAuth(correo, password, 'POST')
            .then((resp) => {
                dispatch(handleLoading());
                if (resp.token) {
                    window.location.href = "/";
                    window.localStorage.setItem("token", resp.token);
                }
                else if (resp.message) {
                    let mensaje = '';
                    if (resp.message === "password incorrecto") {
                        mensaje = 'Correo/contraseña incorrectos'
                    }
                    else {
                        mensaje = resp.message;
                    }
                    dispatch(showAlert({ show: true, type: 'warning', title: 'Error', text: mensaje }));
                }
                else {
                    dispatch(showAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                }
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorLogin", error);
            });
    };
}

export const fetchGetMe = () => {
    return dispatch => {
        apiGetMe()
            .then((res) => {
                if (res.status === 200) {
                    dispatch(signInUser(res.body));
                }
                else if (res.status === 401) {
                    window.location.href = "/Login";
                    console.log('sin autorización');
                }
                else {
                    console.log('error');
                    dispatch(signInUser(initState.user));
                }
            })
            .catch((error) => {
                console.log("errorGetMe", error);
                dispatch(signInUser(initState.user));
            });
    };
}

export const signOut = () => {
    return (dispatch) => {
        dispatch(signOutUser());
        window.localStorage.removeItem("token");
        window.location.href = "/Login";
    }
};

export const resetPasswordUser = (uuid, password) => {
    return dispatch => {
        apiEditPassword(uuid, 'PUT', password)
            .then((resp) => {
                switch (resp) {
                    case '204':
                        dispatch(showAlert({ show: true, type: 'success', title: 'Contraseña actualizada', text: 'Se ha cambiado con éxito su contraseña.' }));
                        break;
                    default:
                        dispatch(showAlert({ show: true, type: 'warning', title: 'Error', text: resp.message }));
                        break;
                }
            })
            .catch((error) => {
                console.log("errorResetPswd", error);
            });
    };
}

export const sendEmailUser = (email) => {
    return dispatch => {
        apiChangePassword(email, 'POST')
            .then((resp) => {
                switch (resp) {
                    case '204':
                        dispatch(showAlert({ show: true, type: 'success', title: 'Correo enviado', text: 'Se ha enviado un correo a la dirección ingresada con las instrucciones para restablecer su contraseña.' }));
                        break;
                    case '400':
                        dispatch(showAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                        break;
                    case '404':
                        dispatch(showAlert({ show: true, type: 'warning', title: 'Correo no encontrado', text: 'El correo ingresado no se encuentra registrado.' }));
                        break;
                    case '429':
                        dispatch(showAlert({ show: true, type: 'error', title: 'Error', text: 'Ha excedido el límite de peticiones, por favor intente más tarde.' }));
                        break;
                    case '500':
                        dispatch(showAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                        break;
                }
            })
            .catch((error) => {
                console.log("errorResetPswd", error);
            });
    };
}

export const fetchGetMenu = () => {
    return dispatch => {
        apiGetMenu()
            .then((resp) => {
                dispatch(getMenu(resp));
            })
            .catch((error) => {
                console.log("errorGetMenu", error);
                dispatch(getMenu(initState.menu));
            });
    };
}

export const resetAlert = () => {
    return dispatch => {
        dispatch(hideAlert());
    }
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SIGN_IN_USER:
            return { ...state, user: { token: localStorage.token, ...action.payload} };
        case SIGN_OUT_USER:
            return { ...state, user: {} };
        case GET_MENU:
            return { ...state, menu: action.payload };
        case SHOW_ALERT:
            return { ...state, alerta: action.payload };
        case HIDE_ALERT:
            return { ...state, alerta: initState };
        default:
            return state;
    }
};

export default authReducer;
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ModalAltaGasolina from './AltaRegGasolina';
import { connect } from 'react-redux';
import { fetchGetCostos, fetchGetRegistros, fetchAddRegistro, fetchDelRegistro } from '../../Reducers/gasolina';
import moment from 'moment';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

import Datatable from '../Shared/DataTable';

const initialState = {
    columns: ['Fecha', 'Ingreso', 'Costo p/litro', 'Opciones'],
    columnsGral: ['Fecha', 'Ingreso', 'Costo p/litro'],
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    estatusTab: 'true',
    showModal: false,
    showDeleteAlert: false,
    id_registro: -1,
};

const configTable = {
    columnDef: {
        opciones: (value) => <Button className="btn btn-raised btn-danger" onClick={() => value.eliminar(value.id)} ><i className="fas fa-trash-alt"></i></Button>
    },
    columnStyle: {}
}

const configTableGral = {
    columnDef: {},
    columnStyle: {}
}

class RegistrosGasolina extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.toggleModal = this.toggleModal.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
        this.hideDeleteAlert = this.hideDeleteAlert.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.updateRegistros = this.updateRegistros.bind(this);
    }

    componentDidMount() {
        const { vehiculo, getCostos, getRegistros, fechaInicial, fechaFinal } = this.props;
        const { size } = this.state;

        let obj = {
            vehiculoId: vehiculo,
            fechaInicio: fechaInicial,
            fechaFin: fechaFinal,
            limit: size,
            offset: '0'
        }
        getCostos();
        getRegistros(obj);

    }

    updateRegistros() {
        const { size } = this.state;
        const { vehiculo, fechaInicial, fechaFinal, getRegistros } = this.props;

        let obj = {
            vehiculoId: vehiculo,
            fechaInicio: fechaInicial,
            fechaFin: fechaFinal,
            limit: size,
            offset: '0'
        }

        this.setState({ activePage: 1 });
        getRegistros(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size } = this.state;
        const { vehiculo, fechaInicial, fechaFinal, getRegistros } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            vehiculoId: vehiculo,
            fechaInicio: fechaInicial,
            fechaFin: fechaFinal,
        }
        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }

        getRegistros(obj);
    }

    mapArray(data) {
        const { fechaInicial, fechaFinal } = this.props;
        let dataTable = [];
        data.map((elm) => {
           /* let fecha_elm_format = moment(elm.createdAt).format('DD/MM/YYYY');
            let fecha_ini_format = moment(fechaInicial).format('DD/MM/YYYY');
            let fecha_fin_format = moment(fechaFinal).format('DD/MM/YYYY');*/
            let fecha_elm = moment(elm.createdAt);
            let fecha_ini = moment(fechaInicial);
            let fecha_fin = moment(fechaFinal);
            if ((fecha_elm.isBetween(fecha_ini, fecha_fin))/* || (fecha_ini_format === fecha_elm_format) ||(fecha_fin_format === fecha_elm_format)*/ ) {
                dataTable.push({
                    fecha: moment(elm.createdAt).format('DD/MM/YYYY HH:mm'),
                    ingreso: '$ ' + elm.ingreso,
                    costo: '$ ' + elm.costo,
                    opciones: {
                        id: elm.id,
                        eliminar: this.handleDeleteAlert
                    }
                    }) ;
                }            
        });
        return dataTable;
    }

    mapArrayGral(data) {
        const { fechaInicial, fechaFinal } = this.props;
        let dataTable = [];
        data.map((elm) => {
            let fecha_elm = moment(elm.createdAt);
            let fecha_ini = moment(fechaInicial);
            let fecha_fin = moment(fechaFinal);
            if (fecha_elm.isBetween(fecha_ini, fecha_fin) ) {
                dataTable.push({
                    fecha: moment(elm.createdAt).format('DD/MM/YYYY HH:mm'),
                    ingreso: '$ ' + elm.ingreso,
                    costo: '$ ' + elm.costo
                    }) ;
                }            
        });
        return dataTable;
    }

    handleDeleteAlert(id) {
        this.setState({
            showDeleteAlert: true,
            id_registro: id
        });
    }

    hideDeleteAlert() {
        this.setState({
            showDeleteAlert: false,
            id_registro: -1
        });
    }

    handleDelete() {
        this.props.delRegistro(this.state.id_registro);
        this.hideDeleteAlert();
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
            id_registro: -1,
        });
    }

    render() {
        const { registrosGasolina, costos, addRegistro, vehiculo, usuario } = this.props;
        const { showModal, showDeleteAlert, columns, activePage, size, total, columnsGral } = this.state;

        if(usuario.tipo && usuario.tipo.id === 1){
            return (
                <div>
                    <SweetAlert show={showDeleteAlert} type="warning" confirmButtonText="Eliminar" cancelButtonText="Cancelar" showCancelButton title="Eliminar registro" text="¿Está seguro que desea eliminar el registro seleccionado?" onConfirm={this.handleDelete} onCancel={this.hideDeleteAlert} />
                    <br />
                    {usuario.tipo.id === 1 &&
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button className="btn btn-raised btn-info" onClick={this.toggleModal}><i className="fa fa-plus"></i> Agregar ingreso</Button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <Datatable tableProps={{ columns, data: this.mapArray(registrosGasolina.rows), configTable }} total={registrosGasolina.total || total} activePage={activePage}
                                    size={size} paginacionEvent={this.paginationHandler} showSearch={false} />
                            </div>
                        </div>
                    </div>
                    {showModal && <ModalAltaGasolina idVehiculo={vehiculo} show={showModal} add={addRegistro} onHide={this.toggleModal} handleModal={this.toggleModal}
                        costoPromedio={costos}
                    />}
                </div>
            );
        }
        else{
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <Datatable tableProps={{ columns:columnsGral, data: this.mapArrayGral(registrosGasolina.rows), configTableGral }} total={registrosGasolina.total || total} activePage={activePage}
                                    size={size} paginacionEvent={this.paginationHandler} showSearch={false} />
                            </div>
                        </div>
                    </div>                   
                </div>
            );

        }
    }
}


export default connect(state => ({
    registrosGasolina: state.gasolina.gasolina,
    costos: state.gasolina.costo,
    usuario: state.auth.user
}), {
        getCostos: fetchGetCostos, getRegistros: fetchGetRegistros, addRegistro: fetchAddRegistro,
        delRegistro: fetchDelRegistro
    }, null, { withRef: true })(RegistrosGasolina);

import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import mainStore from "../Reducers/index";


const store = createStore(
    mainStore,
    applyMiddleware(thunk)
    );

export default store;
const initialState = {
    showModal: false,
    alerta: {
        show: false,
        type: 'warning',
        title: '',
        text: '',
        confirmButtonText: '',
    },
    showLoading: false
};

const SHOW_MODAL = "SHOW_MODAL";
const HANDLE_ALERT = "HANDLE_ALERT";
const SHOW_LOADER = "SHOW_LOADER";

const handleModal = () => ({ type: SHOW_MODAL });
export const handleLoading = () => ({ type: SHOW_LOADER });
const handleAlert = (alert) => ({ type: HANDLE_ALERT, payload: alert });

export const fetchHandleModal = () => {
    return dispatch => {
        dispatch(handleModal());
    }
}

export const fetchHandleLoading = () => {
    return dispatch => {
        dispatch(handleLoading());
    }
}

export const fetchHandleAlert = (objAlerta) => {
    return dispatch => {
        dispatch(handleAlert(objAlerta));
    };
}

export const fetchHideAlert = () => {
    return dispatch => {
        dispatch(handleAlert(initialState.alerta));
    }
}

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return { ...state, showLoading: !state.showLoading };
        case SHOW_MODAL:
            return { ...state, showModal: !state.showModal };
        case HANDLE_ALERT:
            return { ...state, alerta: action.payload };       
        default:
            return { ...state };
    }
};

export default generalReducer;
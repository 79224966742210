import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetVehiculosMonitor = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'monitor/vehiculos';
    return fetch(url, request).then(res => res.json());
};

export const apiGetVehiculo = async (id_vehiculo) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `monitor/vehiculos/${id_vehiculo}/` ;
    return fetch(url, request).then(res => res.json());
};

export const apiDesconectarVehiculo = async (id_vehiculo) =>{
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `monitor/desconectar/${id_vehiculo}/` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiSendCommand = async (body, id_vehiculo) =>{
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `monitor/comando/${id_vehiculo}/` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
    //.then(res => JSON.stringify(res.status));
};

export const apiUpdateVehiculoMonitor = async (id_vehiculo) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `monitor/actualizar/${id_vehiculo}/` ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './login.css';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import { signIn, resetAlert } from '../../Reducers/auth';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

import { Modal } from 'react-bootstrap';
import Loader from 'react-loaders';

const initialState = {
  email: {
    value: '',
    valid: false,
    touched: false,
  },
  password: {
    value: '',
    valid: false,
    touched: false,
  },
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlert = this.handleAlert.bind(this);

    this.state = initialState;
  }

  submit() {
    const { email, password } = this.state;
    if (email.valid && password.valid) {
      this.props.signIn(email.value, password.value);
    } else {
      this.setState({
        email: {
          ...this.state.email,
          touched: true,
        },
        password: {
          ...this.state.password,
          touched: true,
        }
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: {
        value: value,
        valid: this.inputValidation(name, value),
        touched: true,
      }
    });
  }

  inputValidation(name, value) {
    switch (name) {
      case 'email':
        // eslint-disable-next-line
        return RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(value);
      case 'password':
        return RegExp(/^.{6,}$/).test(value);
      default:
        return false;
    }
  }

  handleAlert(){
    this.setState(initialState);
    this.props.resetAlert();
  }

  render() {
    const { email, password } = this.state;
    const {alerta, showLoading} = this.props;

    
    return (
      <div className="login">
      <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText="Aceptar"  title={alerta.title}
       text={alerta.text} onConfirm={this.handleAlert}  />
        <form className="login-form" autoComplete="off">
          <div className="input-group input-group-lg">
            <div className="input-group-addon"><span className="fa fa-lg fa-envelope" /></div>
            <input type="email" name="email" className="form-control" placeholder="Correo" id="user-name"
              value={email.value} onChange={this.handleChange} required tabIndex="1" />
          </div>
          {!email.valid && email.touched && <p className="form-alert">*Formato de correo inválido</p> }
          <div className="input-group input-group-lg">
            <div className="input-group-addon"><span className="fa fa-lg fa-key" /></div>
            <input name="password" type="password" className="form-control" placeholder="Contraseña" maxLength="40"
              value={password.value} onChange={this.handleChange} required tabIndex="2" minLength="6"/>
          </div>
          {!password.valid && password.touched && <p className="form-alert">*La contraseña debe de ser al menos 6 carácteres (40 max), con 1 mayúscula, 1 minúscula, 1 dígito, 1 carácter especial</p>}
          <Button id="btn_login" className="btn fa-lg" onClick={this.submit} tabIndex="3" >Iniciar sesión</Button>
          <br />
          <br />
          <div className="login-bar text-right">
            <Link className="" to={{ pathname: '/Recuperar' }}>Recuperar contraseña</Link> &nbsp;&nbsp;
        </div>
        </form>

        <Modal className="Modal-loader" bsSize="small" show={showLoading}>
                    <Modal.Body>
                        <div className="col-xs-12">
                            <div className="col-lg-7 col-lg-offset-6">
                                <Loader type="line-scale" style={{ transform: "scale(2)" }} />
                                <br />
                                Cargando...
                                </div>
                        </div>
                    </Modal.Body>
                </Modal>
      </div>
    );
  }
}

export default connect((state) =>
  ({
    alerta : state.auth.alerta,
    showLoading: state.general.showLoading, 
  }), { signIn, resetAlert })(Login);
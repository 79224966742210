import React, { Component } from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

class HorariosVehiculo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dp0: { dia: 0, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//domingo
            dp1: { dia: 1, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//lunes
            dp2: { dia: 2, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//martes
            dp3: { dia: 3, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//miercoles
            dp4: { dia: 4, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//jueves
            dp5: { dia: 5, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//viernes
            dp6: { dia: 6, entrada: moment(start).format("HH:mm"), salida: moment(end).format("HH:mm"), selected: 0, id: 0, checkbox: false },//sábado
            actualizado: false
        };

        this.updateState = this.updateState.bind(this);
    }

    handleChangeDTP(name, value) {
        switch (name) {
            case 'startLunes':
                this.setState(({ dp1 }) => ({ dp1: { ...dp1, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endLunes':
                this.setState(({ dp1 }) => ({ dp1: { ...dp1, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startMartes':
                this.setState(({ dp2 }) => ({ dp2: { ...dp2, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endMartes':
                this.setState(({ dp2 }) => ({ dp2: { ...dp2, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startMiercoles':
                this.setState(({ dp3 }) => ({ dp3: { ...dp3, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endMiercoles':
                this.setState(({ dp3 }) => ({ dp3: { ...dp3, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startJueves':
                this.setState(({ dp4 }) => ({ dp4: { ...dp4, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endJueves':
                this.setState(({ dp4 }) => ({ dp4: { ...dp4, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startViernes':
                this.setState(({ dp5 }) => ({ dp5: { ...dp5, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endViernes':
                this.setState(({ dp5 }) => ({ dp5: { ...dp5, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startSabado':
                this.setState(({ dp6 }) => ({ dp6: { ...dp6, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endSabado':
                this.setState(({ dp6 }) => ({ dp6: { ...dp6, salida: moment(value).format("HH:mm") } }));
                break;
            case 'startDomingo':
                this.setState(({ dp0 }) => ({ dp0: { ...dp0, entrada: moment(value).format("HH:mm") } }));
                break;
            case 'endDomingo':
                this.setState(({ dp0 }) => ({ dp0: { ...dp0, salida: moment(value).format("HH:mm") } }));
                break;
            default:
                break;
        }
    };

    hadleCheckbox(e) {
        const { id, checked } = e.target;

        let value = (checked ? 1 : 0);

        switch (id) {
            case 'dp1':
                this.setState(({ dp1 }) => ({ dp1: { ...dp1, selected: value, checkbox: checked} }));
                break;
            case 'dp2':
                this.setState(({ dp2 }) => ({ dp2: { ...dp2, selected: value, checkbox: checked} }));
                break;
            case 'dp3':
                this.setState(({ dp3 }) => ({ dp3: { ...dp3, selected: value, checkbox: checked} }));
                break;
            case 'dp4':
                this.setState(({ dp4 }) => ({ dp4: { ...dp4, selected: value, checkbox: checked} }));
                break;
            case 'dp5':
                this.setState(({ dp5 }) => ({ dp5: { ...dp5, selected: value, checkbox: checked} }));
                break;
            case 'dp6':
                this.setState(({ dp6 }) => ({ dp6: { ...dp6, selected: value, checkbox: checked} }));
                break;
            case 'dp0':
                this.setState(({ dp0 }) => ({ dp0: { ...dp0, selected: value, checkbox: checked} }));
                break;
            default:
                break;
        }
    }

    updateState() {
        const { horarios } = this.props;

        horarios.map(horario => {
            switch (horario.dia) {
                case 0:
                    this.setState(({ dp0 }) => ({ dp0: { ...dp0, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true } }));
                    break;
                case 1:
                    this.setState(({ dp1 }) => ({ dp1: { ...dp1, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
                case 2:
                    this.setState(({ dp2 }) => ({ dp2: { ...dp2, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
                case 3:
                    this.setState(({ dp3 }) => ({ dp3: { ...dp3, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
                case 4:
                    this.setState(({ dp4 }) => ({ dp4: { ...dp4, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
                case 5:
                    this.setState(({ dp5 }) => ({ dp5: { ...dp5, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
                case 6:
                    this.setState(({ dp6 }) => ({ dp6: { ...dp6, entrada: moment(horario.entrada, "HH:mm:ss").format("HH:mm"), salida: moment(horario.salida, "HH:mm").format("HH:mm"), selected: 1, id: horario.id, checkbox:true }  }));
                    break;
            }
        });
        this.setState({ actualizado: true });
    }

    render() {

        const { dp1, dp2, dp3, dp4, dp5, dp6, dp0, actualizado } = this.state;
        const { horarios } = this.props;

        if (horarios && horarios.length > 0 && !actualizado) {
            this.updateState();
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                            <label htmlFor="rendimiento" className="col-form-label">Horario de trabajo</label>
                            <table  className="table tabla_horarios" responsive='true'>
                                <thead>
                                    <tr>
                                        <th>Seleccionar</th>
                                        <th>Día</th>
                                        <th>Hora inicio</th>
                                        <th>Hora fin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp1" id="dp1" checked={dp1["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Lunes
                                    </td>
                                        <td>
                                            <DateTime name="startLunes" dateFormat={false} value={dp1["entrada"]} onChange={moment => this.handleChangeDTP("startLunes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endLunes" dateFormat={false} value={dp1["salida"]} onChange={moment => this.handleChangeDTP("endLunes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp2" id="dp2" checked={dp2["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Martes
                                    </td>
                                        <td>
                                            <DateTime name="startMartes" dateFormat={false} value={dp2["entrada"]} onChange={moment => this.handleChangeDTP("startMartes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endMartes" dateFormat={false} value={dp2["salida"]} onChange={moment => this.handleChangeDTP("endMartes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp3" id="dp3" checked={dp3["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Miércoles
                                    </td>
                                        <td>
                                            <DateTime name="startMiercoles" dateFormat={false} value={dp3["entrada"]} onChange={moment => this.handleChangeDTP("startMiercoles", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endMiercoles" dateFormat={false} value={dp3["salida"]} onChange={moment => this.handleChangeDTP("endMiercoles", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="chckbox" name="dp4" id="dp4" checked={dp4["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Jueves
                                    </td>
                                        <td>
                                            <DateTime name="startJueves" dateFormat={false} value={dp4["entrada"]} onChange={moment => this.handleChangeDTP("startJueves", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endJueves" dateFormat={false} value={dp4["salida"]} onChange={moment => this.handleChangeDTP("endJueves", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp5" id="dp5" checked={dp5["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Viernes
                                    </td>
                                        <td>
                                            <DateTime name="startViernes" dateFormat={false} value={dp5["entrada"]} onChange={moment => this.handleChangeDTP("startViernes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endViernes" dateFormat={false} value={dp5["salida"]} onChange={moment => this.handleChangeDTP("endViernes", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp6" id="dp6" checked={dp6["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Sábado
                                    </td>
                                        <td>
                                            <DateTime name="startSabado" dateFormat={false} value={dp6["entrada"]} onChange={moment => this.handleChangeDTP("startSabado", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endSabado" dateFormat={false} value={dp6["salida"]} onChange={moment => this.handleChangeDTP("endSabado", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" name="dp0" id="dp0" checked={dp0["checkbox"]} onChange={(event) => this.hadleCheckbox(event)} />
                                        </td>
                                        <td>
                                            Domingo
                                    </td>
                                        <td>
                                            <DateTime name="startDomingo" dateFormat={false} value={dp0["entrada"]} onChange={moment => this.handleChangeDTP("startDomingo", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                        <td>
                                            <DateTime name="endDomingo" dateFormat={false} value={dp0["salida"]} onChange={moment => this.handleChangeDTP("endDomingo", moment)} inputProps={{ className: "dtpicker_center form-control" }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HorariosVehiculo;
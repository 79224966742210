import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loaders';
import { Modal } from 'react-bootstrap';

class Content extends Component {

    render() {
        const {body, showLoading} = this.props;
    
        return (
            <div className="Content">
            {body}

            <Modal className="Modal-loader" bsSize="small" show={showLoading}>
                    <Modal.Body>
                        <div className="col-xs-12">
                            <div className="col-lg-7 col-lg-offset-6">
                                <Loader type="line-scale" style={{ transform: "scale(2)" }} />
                                <br />
                                Cargando...
                                </div>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
        );
      }
}


export default connect(
    (state) => ({
        showLoading: state.general.showLoading,
    }),{  })(Content);
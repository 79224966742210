import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import './login.css';
import { sendEmailUser, resetAlert } from '../../Reducers/auth';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

const initialState = {
  email: {
    value: '',
    valid: false,
    touched: false,
  }
}

class Recuperar extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlert = this.handleAlert.bind(this);

    this.state = initialState;
  }
  
  submit() {
    const { email } = this.state;
    if (email.valid) {
      this.props.sendEmailUser(email.value);
    } else {
      this.setState({
        email: {
          ...this.state.email,
          touched: true,
        }        
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: {
        value: value,
        valid: this.inputValidation(name, value),
        touched: true,
      }
    });
  }

  inputValidation(name, value) {
        // eslint-disable-next-line
        return RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(value);
  }

  handleAlert(){
    this.setState(initialState);
    this.props.resetAlert();
    window.location.href = "/Login";
  }

  render() {
    const { email } = this.state;
    const {alerta} = this.props;

    return (
      <div className="login">
       <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText="Aceptar"  title={alerta.title}
       text={alerta.text} onConfirm={this.handleAlert}  />
      <form className="login-form" autoComplete="off">
      <label>El link para restablecer la contraseña será enviado a su correo.</label>
      <br/>
        <div className="input-group input-group-lg">
           <div className="input-group-addon"><span className="fa fa-lg fa-envelope"/></div>
           <input type="email" name="email" className="form-control" placeholder="Correo" id="email" required tabIndex="1" 
           onChange={this.handleChange} defaultValue={email.value}/>
        </div>  
        {!email.valid && email.touched && <p className="form-alert">*Formato de correo inválido</p> }
        <br/>   
        <Button id="btn_enviar" className="btn fa-lg" onClick={this.submit}  tabIndex="3" >Enviar</Button>
        <br/> 
        <br/> 
        <div className="login-bar text-right">   
          <Link className="" to={{ pathname: '/Login' }}><i className="fas fa-arrow-circle-left"></i>  Regresar</Link> &nbsp;&nbsp; 
        </div>  
      </form>   
    </div>
    );
  }
}

export default connect((state) =>
  ({
    alerta : state.auth.alerta
  }), { sendEmailUser, resetAlert })(Recuperar);

import React, { Component } from 'react';
import SimpleExample from '../Mapa/Mapa';
import ListaPaginada from './ListaPaginada';
import {
  fetchGetMonitor, fetchGetVehiculoMonitor,
  fetchHandleAlertMonitor, fetchHideAlertMonitor, fetchSendCommand
} from '../../Reducers/monitor';
import { fetchGetParadas } from '../../Reducers/historico';
import { connect } from 'react-redux';
import Detalles from './Detalles';
import moment from 'moment';
import Select from 'react-select';

import Search from '../SearchBar/Search'

import { Button } from 'react-bootstrap';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

import Leaflet from 'leaflet';

let options = [
  { value: '1', label: 'Conectados' },
  { value: '2', label: 'Desconectados' },
];

let optsVehiculos = [];

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "unset",
    background: "transparent",
    borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
    "&:hover": {
      boxShadow: "none"
    },
  }),
  menu: () => ({
    backgroundColor: "white",
    boxShadow: "1px 2px 6px #888888",
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 2,
  }),
  menuList: () => ({
    overflowY: "auto",
    maxHeight: "150px"
  })
};

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

class Monitor extends Component {
  constructor() {
    super();
    this.state = {
      marcador: -1,
      status: -1,
      direccion: [],
      showDetails: false,
      startDate: moment(start).toISOString(),
      endDate: moment(end).toISOString(),
      conectados: 0,
      id_vehiculo: -1,
      accion: -1,
      selectedOption: null,
      selectedStatus: null,
      ubicacion: ''
    }
    this.zoomMarker = this.zoomMarker.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSelectOptions = this.handleSelectOptions.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.resetFiltros = this.resetFiltros.bind(this);
    this.resetBounds = this.resetBounds.bind(this);
    this.searchFiltros = this.searchFiltros.bind(this);
    this.getAddress = this.getAddress.bind(this);

    this.showAlertMonitor = this.showAlertMonitor.bind(this);
    this.handleStatusVehiculo = this.handleStatusVehiculo.bind(this);
    this.hideAlertaMonitor = this.hideAlertaMonitor.bind(this);

    this.geocoder = new window.google.maps.Geocoder;

    this.childMapa = React.createRef();
    this.childBusqueda = React.createRef();

  }

  componentDidMount() {
    this.props.get();
    try {
      this.interval = setInterval(async () => {
        this.props.get();
      }, 30000);
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  zoomMarker(e, id) {
    e.preventDefault();
    this.setState({ marcador: id })
  }

  toggleModal() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  handleDetails(e, id) {
    const { getVehiculo, getParadas } = this.props;
    getVehiculo(id);
    getParadas(id, this.state.startDate, this.state.endDate);
    this.toggleModal();
  }

  handleSelectChange = (selectedOption) => {
    this.setState({
      ...this.state, selectedOption
    });
    if (selectedOption) {
      this.setState({ id_vehiculo: selectedOption.value, nombreVehiculo: selectedOption.label });
    }
    else {
      this.setState({ id_vehiculo: -1, nombreVehiculo: '' });
    }
  }

  handleSelectStatus = (selectedStatus) => {
    this.setState({
      ...this.state, selectedStatus
    });
  }

  getAddress(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleSelectOptions() {
    optsVehiculos = [];
    this.props.monitor.map((vehiculo) =>
      optsVehiculos.push({ value: vehiculo.vehiculo.id, label: vehiculo.vehiculo.nombre })
    );
    return optsVehiculos;
  }

  resetFiltros() {
    this.setState({
      selectedOption: null,
      selectedStatus: null,
      ubicacion: '',
      marcador: -1,
      status: -1
    });
    this.childMapa.current.resetCenter();
    this.childBusqueda.current.resetSearch();
  }

  resetBounds() {
    this.setState({
      ubicacion: '',
      marcador: -1,
    });
    this.childMapa.current.resetCenter();
  }

  searchFiltros() {
    let markerZoom = this.state.selectedOption;
    let estatus = this.state.selectedStatus;
    let buscar_ubicacion = this.childBusqueda.current.state.lugar;
    if (buscar_ubicacion.id !== -1) {
      this.geocoder.geocode({ 'placeId': buscar_ubicacion.id }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          let bounds = results[0].geometry.bounds;
          let objKeys = Object.keys(bounds);
          this.setState({
            ubicacion: Leaflet.latLngBounds(Leaflet.latLng(bounds[objKeys[0]].j, bounds[objKeys[1]].j), Leaflet.latLng(bounds[objKeys[0]].l, bounds[objKeys[1]].l))
          });
        }
      });
    }
    if (markerZoom) {
      this.setState({
        marcador: markerZoom.value
      });
    }
    if (estatus) {
      this.setState({
        status: parseInt(estatus.value, 10)
      });
    }
  }

  showAlertMonitor(id, tipo) {
    this.props.handleAlertMonitor(tipo);
    this.setState({
      id_vehiculo: id,
      accion: tipo
    });
  }

  handleStatusVehiculo() {
    const { id_vehiculo, accion, showDetails } = this.state;
    let datos = [];
    switch (accion) {
      case 1: datos = {
        "comando": "110"
      };
        break;
      case 2:
        datos = {
          "comando": "109"
        };
        break;
    }
    if (showDetails) {
      this.props.sendCommand(datos, id_vehiculo, 'MonitorDetalles');
    }
    else {
      this.props.sendCommand(datos, id_vehiculo, 'Monitor');
    }
  }

  hideAlertaMonitor() {
    this.props.hideAlertMonitor();
    this.setState({ id_vehiculo: -1, accion: -1 });
  }

  render() {
    const { monitor, vehiculoMonitor, paradas, alertaMonitor, usuario } = this.props;
    const { selectedOption, selectedStatus, ubicacion, marcador, status, id_vehiculo, accion } = this.state;

    return (
      <div>

        <SweetAlert show={alertaMonitor.show} type={alertaMonitor.type} confirmButtonText={alertaMonitor.confirmButtonText} cancelButtonText="Cancelar"
          showCancelButton title={alertaMonitor.title} text={alertaMonitor.text}
          onConfirm={this.handleStatusVehiculo} onCancel={this.hideAlertaMonitor} />

        <div className="col-12">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-12 ">
              <div className="card text-white text-center bg-success  mb-3" >
                <div className="card-body">
                  <h5 className="card-title"><Total_conectados redis={this.props.monitor} /></h5>
                  <p className="card-text">Vehículos conectados</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12 ">
              <div className="card text-white text-center bg-primary mb-3">
                <div className="card-body">
                  <h5 className="card-title"><Total_desconectados redis={this.props.monitor} /></h5>
                  <p className="card-text">Vehículos desconectados</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 ">
                      <div className="form-group">
                        <label htmlFor="ubicacion" className="col-form-label">Búsqueda por ubicación</label>
                        <Search ref={this.childBusqueda} />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 ">
                      <div className="form-group">
                        <label htmlFor="txt_buscar_vehiculo" className="col-form-label">Búsqueda por vehículo</label>
                        <Select options={this.handleSelectOptions()} value={selectedOption} placeholder="- Seleccione -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectChange} isClearable />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 ">
                      <div className="form-group">
                        <label htmlFor="txt_buscar_estado" className="col-form-label">Filtrar por estado</label>
                        <Select options={options} value={selectedStatus} placeholder="- Seleccione -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectStatus} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <div className="btn-toolbar" role="toolbar" >
                        <div className="btn-group " role="group" aria-label="First group">
                          <Button type="button" className="btn btn-raised btn-primary" onClick={this.resetFiltros}><i className="fas fa-trash"></i> Borrar filtros</Button>
                        </div>
                        <div className="btn-group " role="group" aria-label="Second group">
                          <Button type="button" className="btn btn-raised btn-info" onClick={this.resetBounds}><i className="far fa-map"></i> Restablecer mapa</Button>
                        </div>
                        <div className="btn-group" role="group" aria-label="Third group">
                          <Button type="button" className="btn btn-raised btn-success" onClick={this.searchFiltros}><i className="fas fa-search"></i> Buscar</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body card_mapa">
                  <SimpleExample ref={this.childMapa} markers={monitor} selectMarker={marcador} handleDetails={this.handleDetails} estatus={status} bounds={ubicacion} />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <ListaPaginada vehiculos={monitor} cambiarApagado={this.showAlertMonitor} handleZoom={this.zoomMarker} permisos={usuario.tipo.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDetails && <Detalles show={this.state.showDetails} onHide={this.toggleModal} paradas={paradas} vehiculo={vehiculoMonitor} cambiarApagadoDetalles={this.showAlertMonitor} permisos={usuario.tipo.id} />}
      </div>
    );
  }
}

class Total_conectados extends Component {
  render() {
    let total = 0;
    this.props.redis.map((item) => {
      if (item.estado === 1 && item.vehiculo) {
        total += 1;
      }
    })
    return (total);
  }
}

class Total_desconectados extends Component {
  render() {
    let total = 0;
    this.props.redis.map((item) => {
      if (item.estado === 2 && item.vehiculo) {
        total += 1;
      }
    })
    return (total);
  }
}


export default connect(state => ({
  monitor: state.monitor.vehiculosMonitor,
  vehiculoMonitor: state.monitor.vehiculoSelMonitor,
  paradas: state.historico.paradasVehiculo,
  alertaMonitor: state.monitor.alertaMonitor,
  usuario: state.auth.user
}), {
    get: fetchGetMonitor,
    getVehiculo: fetchGetVehiculoMonitor,
    getParadas: fetchGetParadas,
    handleAlertMonitor: fetchHandleAlertMonitor,
    hideAlertMonitor: fetchHideAlertMonitor,
    sendCommand: fetchSendCommand,
  })(Monitor);
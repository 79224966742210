import React, { Component } from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';
import ListaAlertas from './ListaAlertas';
import { connect } from 'react-redux';
import { fetchGetHistorico } from '../../Reducers/alertas';
import { Button } from 'react-bootstrap';

import Select from 'react-select';

import { fetchGetCatVehiculos, fetchGetCatUsuarios } from '../../Reducers/catalogos';


let optionsVehiculos = [];
let optionsUsuarios = [];
const optionsAlertas = [
    { value: 1, label: "Botón de pánico" },
    { value: 2, label: "Batería baja" },
    { value: 3, label: "Exceso de velocidad" },
    { value: 4, label: "Fuera del área" },
    { value: 5, label: "Perdida de conexión (socket))" },
    { value: 6, label: "Reconexión (socket)" },
    { value: 7, label: "Señal de GPS mala" },
    { value: 8, label: "Fuera del horario" },
];

const customStyles = {
    control: () => ({
        display: "flex",
        alignItems: "center",
        border: 0,
        height: "unset",
        background: "transparent",
        borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
        "&:hover": {
            boxShadow: "none"
        },
    }),
    menu: () => ({
        backgroundColor: "white",
        boxShadow: "1px 2px 6px #888888",
        position: "absolute",
        left: 0,
        top: `calc(100% + 1px)`,
        width: "100%",
        zIndex: 2,
    }),
    menuList: () => ({
        overflowY: "auto",
        maxHeight: "150px"
    })
};


let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

class Alertas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dpStart: moment(start),
            dpEnd: moment(end),
            fecha_inicial: moment(start).toISOString(),
            fecha_final: moment(end).toISOString(),
            interval: '',
            tipo: -1,
            chofer: -1,
            vehiculo: -1,
            selectChofer: null,
            selectVehiculo: null,
            selectAlerta: null,
        };

        this.buscarAlertas = this.buscarAlertas.bind(this);
        this.handleOptionsVehiculos = this.handleOptionsVehiculos.bind(this);
        this.handleOptionsUsuarios = this.handleOptionsUsuarios.bind(this);
        this.handleChoferChange = this.handleChoferChange.bind(this);
        this.handleVehiculoChange = this.handleVehiculoChange.bind(this);
        this.handleAlertaChange = this.handleAlertaChange.bind(this);
        this.child = React.createRef();
    }

    componentDidMount() {
        const { getVehiculos, getUsuarios } = this.props;
        getVehiculos({ activo: 'true' });
        getUsuarios({ tipoUsuario: 3, activo:'true' });
    }

    buscarAlertas() {
        this.child.current.updateEvent();
    }

    handleChangeDTP(name, value) {
        switch (name) {
            case 'fromDate':
                this.setState({ dpStart: value, fecha_inicial: moment(value).toISOString(), });
                break;
            case 'fromTime':
                this.setState({ dpStart: value, fecha_inicial: moment(value).toISOString() });
                break;
            case 'toDate':
                this.setState({ dpEnd: value, fecha_final: moment(value).toISOString() });
                break;
            case 'toTime':
                this.setState({ dpEnd: value, fecha_final: moment(value).toISOString() });
                break;
            default:
                break;
        }
    };

    handleChoferChange = (selectChofer) => {
        this.setState({
            ...this.state, selectChofer
        });
        if (selectChofer) {
            this.setState({ chofer: selectChofer.value });
        }
        else {
            this.setState({ chofer: -1 });
        }
    }

    handleVehiculoChange = (selectVehiculo) => {
        this.setState({
            ...this.state, selectVehiculo
        });
        if (selectVehiculo) {
            this.setState({ vehiculo: selectVehiculo.value });
        }
        else {
            this.setState({ vehiculo: -1 });
        }
    }

    handleAlertaChange = (selectAlerta) => {
        this.setState({
            ...this.state, selectAlerta
        });
        if (selectAlerta) {
            this.setState({ tipo: selectAlerta.value });
        }
        else {
            this.setState({ tipo: -1 });
        }
    }

    handleOptionsVehiculos() {
        optionsVehiculos = [];
        this.props.vehiculos.rows.map((vehiculo) =>
            optionsVehiculos.push({ value: vehiculo.id, label: vehiculo.nombre })
        );  
         
        return optionsVehiculos;
    }

    handleOptionsUsuarios() {
        optionsUsuarios = [];
        this.props.usuarios.rows.map((usuario) =>
            optionsUsuarios.push({ value: usuario.id, label: usuario.nombre + ' ' + usuario.apellidoP +' ' + (usuario.apellidoM ? usuario.apellidoM : '')})
        );
       
        return optionsUsuarios;
    }

    render() {
        const { historial, getAlertas } = this.props;
        const {  fecha_inicial, fecha_final, tipo, chofer, vehiculo, selectAlerta, selectChofer, selectVehiculo } = this.state;

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card" >
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4><b><i className="fas fa-exclamation-circle" style={{ color: "#85c9ef" }}></i> Todas las alertas</b></h4>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-3 col-xs-12">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromDate" inputProps={{ className: "dtpicker_center form-control" }} locale="es" onChange={moment => this.handleChangeDTP("fromDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={this.state.dpStart} timeFormat={false} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="far fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="fromTime" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("fromTime", moment)} dateFormat={false} value={this.state.dpStart} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toDate" locale="es" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("toDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" placeholder="Fecha final" value={this.state.dpEnd} timeFormat={false} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="far fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
                                    <DateTime name="toTime" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP("toTime", moment)} dateFormat={false} value={this.state.dpEnd} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-3 col-xs-12">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="fas fa-car"></i></span>&nbsp;&nbsp;&nbsp;
                                        <Select options={this.handleOptionsVehiculos()} value={selectVehiculo} placeholder="- Vehículo -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleVehiculoChange} isClearable  />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="fas fa-bell"></i></span>&nbsp;&nbsp;&nbsp;
                                        <Select options={optionsAlertas}  value={selectAlerta} placeholder="- Tipo de alerta -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleAlertaChange} isClearable  />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className="input-group">
                                        <span className="input-group-addon" ><i className="fas fa-user"></i></span>&nbsp;&nbsp;&nbsp;
                                        <Select value={selectChofer} options={this.handleOptionsUsuarios()} placeholder="- Chofer -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleChoferChange} isClearable />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="btn-toolbar text-right" role="toolbar">
                                        <div className="btn-group text-right" role="group" aria-label="First group">
                                            <Button type="button" className="btn btn-raised btn-info" onClick={this.buscarAlertas}><i className="fas fa-search"></i> Buscar</Button>
                                        </div>
                                        <div className="btn-group text-right" role="group" aria-label="Third group">
                                            <Button type="button" className="btn btn-raised btn-success" onClick={this.buscarAlertas}><i className="fas fa-redo"></i> Actualizar </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <ListaAlertas ref={this.child} get={getAlertas} alertas={historial} fecha_incio={fecha_inicial} fecha_fin={fecha_final} idTipo={tipo} idChofer={chofer} idVehiculo={vehiculo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    historial: state.alertas.historico,
    vehiculos: state.catalogos.catVehiculos,
    usuarios: state.catalogos.usuarios
}), {
    getAlertas: fetchGetHistorico,
        getVehiculos: fetchGetCatVehiculos,
        getUsuarios: fetchGetCatUsuarios
    })(Alertas);

import React, { Component } from 'react';
import { Modal, Button, InputGroup, FormControl, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import DateTime from 'react-datetime';

let fecha_registro = new Date();
fecha_registro.setHours(8, 0, 0, 0);

class ModalAltaGasolina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costo_magna: (props.costoPromedio ? props.costoPromedio.magna : ''),
            costo_premium: (props.costoPromedio ? props.costoPromedio.premium : ''),
            costo_diesel: (props.costoPromedio ? props.costoPromedio.diesel : ''),
            total_ingreso: '',
            costo_seleccionado: '',
            dpfecha: moment(fecha_registro),
            dpfechaISO: moment(fecha_registro).toISOString(),
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectPrecio = this.selectPrecio.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleChangeDTP(value) {
        this.setState({ dpfecha: value, dpfechaISO: moment(value).toISOString(), });
    }

    selectPrecio(e) {
        this.setState({
            costo_seleccionado: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { add, handleModal, idVehiculo } = this.props;
        const { total_ingreso, costo_magna, costo_premium, costo_diesel, costo_seleccionado, dpfechaISO } = this.state;
        let costo_gasolina = 0;

        switch (costo_seleccionado) {
            case 'costo_magna':
                costo_gasolina = costo_magna;
                break;
            case 'costo_premium':
                costo_gasolina = costo_premium;
                break;
            case 'costo_diesel':
                costo_gasolina = costo_diesel;
                break;
        }
        const datos = {
            vehiculoId: idVehiculo,
            ingreso: total_ingreso,
            costo: costo_gasolina,
            fechaIngreso: dpfechaISO
        }
        add(datos);
        handleModal();
    }

    render() {
        const { costo_magna, costo_premium, costo_diesel, total_ingreso, dpfecha } = this.state;

        return (
            <Modal {...this.props} container={this} aria-labelledby="contained-modal-title" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">
                        Nuevo ingreso de gasolina
              </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-4 col-xs-12">
                                <label htmlFor="registryDate" className="col-form-label">Fecha*</label>
                                <div className="input-group">
                                    <span className="input-group-addon" ><i className="far fa-calendar-alt"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="registryDate" locale="es" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP(moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={dpfecha} timeFormat={false} />
                                </div>
                            </div>
                            <div className="col-md-4  col-xs-12">
                                <label htmlFor="registryTime" className="col-form-label">Hora*</label>
                                <div className="input-group">
                                    <span className="input-group-addon" ><i className="far fa-clock"></i></span>&nbsp;&nbsp;&nbsp;
                                        <DateTime name="registryTime" inputProps={{ className: "dtpicker_center form-control" }} onChange={moment => this.handleChangeDTP(moment)} dateFormat={false} value={dpfecha} />
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <FormGroup>
                                    <label htmlFor="costo" className="col-form-label">Ingreso de gasolina*</label>
                                    <InputGroup>
                                        <InputGroup.Addon>$</InputGroup.Addon>
                                        <FormControl defaultValue={total_ingreso} type="text" name="total_ingreso" onChange={this.handleChange} className="form-control" id="total_ingreso" required pattern="^\d*\.?\d*$" />
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="total" className="col-form-label">Costo por litro*</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-xs-12">
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon>
                                            <input type="radio" name="radioGasolina" aria-label="..." id="inlineRadio1" value='costo_magna' onClick={this.selectPrecio} />
                                        </InputGroup.Addon>
                                        <FormControl defaultValue={costo_magna} name="costo_magna" id="costo_magna" type="text" onChange={this.handleChange} pattern="^\d*\.?\d*$" />
                                        <InputGroup.Addon style={{ fontSize: '11px' }}>(magna)</InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon>
                                            <input type="radio" name="radioGasolina" aria-label="..." id="inlineRadio2" value='costo_premium' onClick={this.selectPrecio} />

                                        </InputGroup.Addon>
                                        <FormControl type="text" defaultValue={costo_premium} name="costo_premium" id="costo_premium" onChange={this.handleChange} pattern="^\d*\.?\d*$" />
                                        <InputGroup.Addon style={{ fontSize: '11px' }}>(premium)</InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon>
                                            <input type="radio" name="radioGasolina" aria-label="..." id="inlineRadio3" value='costo_diesel' onClick={this.selectPrecio} required />

                                        </InputGroup.Addon>
                                        <FormControl type="text" defaultValue={costo_diesel} name="costo_diesel" id="costo_diesel" onChange={this.handleChange} pattern="^\d*\.?\d*$" />
                                        <InputGroup.Addon style={{ fontSize: '11px' }}>(diesel)</InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-md-6 leyenda_obligatorios">
                                * Campos obligatorios
                            </div>
                            <div className="col-md-6">
                                <Button className="btn btn-raised btn-info float-right" type="submit" >Guardar</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalAltaGasolina;
import { baseUrl, GET, DELETE, CreateRequest, makeUrlGET} from './general';

export const apiGetAlertas = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'alertas/' ;
    return fetch(url, request).then(res => res.json());
};

export const apiDeleteAlertas = async () =>{
    var request = await CreateRequest(DELETE);
    let url = baseUrl + 'alertas/';
    return fetch(url, request).then(res => res.status);
};

export const apiGetHistoricoAlertas = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'alertas/historico/?'+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

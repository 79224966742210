import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchGetUsuarios, fetchAddUsuario, fetchGetInfoUsuario,
    fetchUpdateUsuario, fetchRestoreUsuario, fetchDeleteUsuario,
    fetchUpdateStatusUsuario, fetchHandleModalUsuario, fetchHideAlertUsuario, 
    fetchHandleAlertUsuario
} from '../../Reducers/usuarios';
//import TablaUsuarios from './TablaUsuarios';
import ListaUsuarios from './ListaUsuarios';
import ModalAltaUsuario from './AltaUsuario';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

class Supervisores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_usuario: -1,
            accion:-1,
            activeTab1: 'nav-link active',
            activeTab2: 'nav-link',
            activeTab3: 'nav-link',
            activeTab: 1,
            usuarioSeleccionado: [],
            tipoUsuario : 2, 
            estatus:'true'
        }
        this.handleTabClick = this.handleTabClick.bind(this);
        this.obtenerUsuario = this.obtenerUsuario.bind(this);
        this.agregarUsuario = this.agregarUsuario.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleUser = this.handleUser.bind(this);
    }
    
    handleTabClick(event) {
        let index = event.target.id;
        switch (index) {
            case '1':
                this.setState({
                    activeTab1: 'nav-link active',
                    activeTab2: 'nav-link',
                    activeTab3: 'nav-link',
                    activeTab: 1,
                    estatus: 'true'
                });
                break;
            case '0':
                this.setState({
                    activeTab1: 'nav-link',
                    activeTab2: 'nav-link active',
                    activeTab3: 'nav-link',
                    activeTab: 2,
                    estatus: 'false'
                });
                break;
            case '3':
                this.setState({
                    activeTab1: 'nav-link',
                    activeTab2: 'nav-link ',
                    activeTab3: 'nav-link active',
                    activeTab: 3,
                    estatus: ''
                });
                break;
        }
    }

    obtenerUsuario(id) {
        var seleccion = this.props.usuarios.rows.filter(item => item.id === id);
        this.setState({
            usuarioSeleccionado: seleccion[0]
        })
        this.props.handleModal();
    }

    agregarUsuario(){
        this.setState({
            usuarioSeleccionado: []
        })
        this.props.handleModal();
    }

    showAlert(id, tipo){
        this.props.handleAlert(tipo);
        this.setState({
            id_usuario: id,
            accion: tipo
        });
    }

    hideAlert(){
        this.props.hideAlert();
        this.setState({id_usuario:-1, accion:-1});
    }

    handleUser(){
        const {accion, id_usuario, activeTab} = this.state;
        const {deleteUsr, restoreUsr, updateStatus} = this.props;
        if(activeTab === 3 ){
            updateStatus(id_usuario, accion);
        } 
        else{
            if(accion === 1){
                restoreUsr(id_usuario);         
           } 
           else if(accion === 2){
               deleteUsr(id_usuario);
           }
           else{
               this.hideAlert();
           }
        }
    }

    render() {
        const { usuarios, getUsrs, addUsr, updateUsr, showModal, handleModal, alerta } = this.props;
        const { usuarioSeleccionado, tipoUsuario, estatus  } = this.state;

        return (
            <div>
                <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText={alerta.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alerta.title} text={alerta.text}
                    onConfirm={this.handleUser} onCancel={this.hideAlert} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-10">
                                        <ul className="nav nav-tabs card-header-tabs pull-left" >
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab1} id="1" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>Activos</a>
                                            </li>
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab2} id="0" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>Inactivos</a>
                                            </li>
                                            <li className='nav-item'>
                                                <a className={this.state.activeTab3} id="3" data-toggle="tab" href="#" role="tab" aria-selected="false" onClick={(e) => this.handleTabClick(e)}>General</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2">
                                        <a href="#" className="btn_agregar" onClick={this.agregarUsuario}><i className="fa fa-plus"></i> Registro</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ListaUsuarios usuarios={usuarios} estatus={estatus} get={getUsrs} edit={this.obtenerUsuario} editState= {this.showAlert} usuario={tipoUsuario}/>
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && <ModalAltaUsuario show={showModal} add={addUsr} onHide={showModal?handleModal:''}
                    usuario={usuarioSeleccionado} update={updateUsr} tipo={tipoUsuario} />}
            </div>
        );
    }
}

export default connect(state => ({
    usuarios: state.usuarios.usuarios,
    showModal: state.usuarios.showModalUsuario,
    alerta: state.usuarios.alertaUsuario
}), {
    getUsrs: fetchGetUsuarios, addUsr: fetchAddUsuario, getUsr: fetchGetInfoUsuario,
        updateUsr: fetchUpdateUsuario, restoreUsr: fetchRestoreUsuario, deleteUsr: fetchDeleteUsuario,
        updateStatus: fetchUpdateStatusUsuario, handleModal: fetchHandleModalUsuario, 
        hideAlert: fetchHideAlertUsuario, handleAlert: fetchHandleAlertUsuario,
    })(Supervisores);
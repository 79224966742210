import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { fetchGetAlertas, fetchDeleteAlertas } from '../../Reducers/alertas';
import { connect } from 'react-redux';
import moment from 'moment';

class Notificaciones extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.dropdownToggle = this.dropdownToggle.bind(this);
    }

    componentDidMount() {
        const { get } = this.props;
        get();
        this.startInterval();
    }

    startInterval() {
        const { get } = this.props;
        get();
        try {
            this.interval = setInterval(async () => {
                get();
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    stopInterval() {
        clearInterval(this.interval);
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    tipoAlerta(id) {
        let alerta = ''
        switch (id) {
            case 1: alerta = ' botón de pánico'
                break;
            case 2: alerta = ' batería baja'
                break;
            case 3: alerta = ' exceso de velocidad'
                break;
            case 4: alerta = ' fuera del área'
                break;
            case 5: alerta = ' pérdida de conexión (socket)'
                break;
            case 6: alerta = ' reconexión (socket)'
                break;
            case 7: alerta = ' señal de GPS mala'
                break;
            case 8: alerta = ' movimiento fuera de horario'
                break;
        }
        return alerta;
    }

    dropdownToggle = (newValue) => {
        const { alertas, deleteAlertas } = this.props;
        if (newValue) {
            this.stopInterval();
        }
        else {
            if (alertas.total > 0) deleteAlertas();
            this.startInterval();
        }
        this.setState({ isOpen: newValue });
    }

    render() {
        const { alertas } = this.props;

        return (
            <Dropdown id="dd_notificaciones" pullRight open={this.state.isOpen} onToggle={(val) => this.dropdownToggle(val)}>
                <Dropdown.Toggle >
                    {alertas.total > 0 ?
                        <span className="badge badge-danger"> {alertas.total} </span>
                        :
                        ''
                    }
                    &nbsp;<i className="fas fa-bell"></i>  &nbsp; Notificaciones
                      </Dropdown.Toggle>
                <Dropdown.Menu >
                    <div className="body_notificaciones">
                        {alertas.total > 0 ?
                            alertas.rows.slice(0).reverse().map((item, index) =>
                                <div key={index}>
                                    <MenuItem divider className="dropdown-divider" />
                                    <a className="list-group-item dropdown-item">
                                        <div className="bmd-list-group-col">
                                            <div className="list-group-item-text">{moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</div>
                                            <div className="list-group-item-heading"><b>{item.vehiculo.nombre}</b> ha emitido una alerta de {this.tipoAlerta(item.tipo)}.</div>
                                        </div>
                                    </a>
                                </div>
                            )
                            :
                            <div>
                                <a className="list-group-item dropdown-item">
                                    <div className="bmd-list-group-col">
                                        <div className="list-group-item-heading text-center">  No se encontraron registros  </div>
                                    </div>
                                </a>
                            </div>
                        }
                    </div>
                    <MenuItem divider className="dropdown-divider" />
                    <Link className="dropdown-item ver_todo" to={{ pathname: '/Alertas' }}>Ver todo</Link>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default connect(state => ({
    alertas: state.alertas.alertas,
}), { get: fetchGetAlertas, deleteAlertas: fetchDeleteAlertas })(Notificaciones);

import {
    apiGetMe,
    apiEditMe,
    apiGetUsuarios,
    apiAddUsuario,
    apiGetUsuario,
    apiUpdateUsuario,
    apiRestoreUsuario,
    apiDeleteUsuario,
} from '../Api/apiUsuarios';
import { NotificationManager } from 'react-notifications';

const initialState = {
    usuarioSeleccionado: [],
    usuarios: {
        rows: [],
        total: 0
    },
    showModalUsuario: false,
    alertaUsuario: {
        show: false,
        type: 'warning',
        title: '',
        text: '',
        confirmButtonText: '',
    }
};

const GET_USUARIOS = "GET_USUARIOS";
const ADD_USUARIOS = "ADD_USUARIOS";
const GET_INFO_USUARIO = "GET_INFO_USUARIO";
const UPDATE_USUARIO = "UPDATE_USUARIO";
const RESTORE_USUARIO = "RESTORE_USUARIO";
const DEL_USUARIO = "DEL_USUARIO";
const SHOW_MODAL_USUARIO = "SHOW_MODAL_USUARIO";
const HANDLE_ALERT_USUARIO = "HANDLE_ALERT_USUARIO";

const getUsuarios = usuarios => ({ type: GET_USUARIOS, payload: usuarios });
const addUsuario = usuario => ({ type: ADD_USUARIOS, payload: usuario });
const getInfoUsuario = usuario => ({ type: GET_INFO_USUARIO, payload: usuario });
const updateUsuario = usuario => ({ type: UPDATE_USUARIO, payload: usuario });
const restoreUsuario = id => ({ type: RESTORE_USUARIO, payload: id });
const deleteUsuario = id => ({ type: DEL_USUARIO, payload: id });
const handleModal = () => ({ type: SHOW_MODAL_USUARIO });
const handleAlert = (alert) => ({ type: HANDLE_ALERT_USUARIO, payload: alert });

export const fetchGetMe = () => {
    return dispatch => {
        apiGetMe()
            .then((usuario) => {
                dispatch(getInfoUsuario(usuario));
            })
            .catch((error) => {
                console.log("errorGetMe", error);
                dispatch(getInfoUsuario(initialState.usuarioSeleccionado));
            });
    };
}

export const fetchUpdateMe = (body) => {
    return dispatch => {
        apiEditMe(body)
            .then((usuario) => {
                dispatch(updateUsuario(usuario));
            })
            .catch((error) => {
                console.log("errorUpdateMe", error);
                dispatch(updateUsuario(initialState.usuarioSeleccionado));
            });
    };
}

export const fetchGetUsuarios = (objs) => {
    return dispatch => {
        apiGetUsuarios(objs)
            .then((usuarios) => {
                dispatch(getUsuarios(usuarios));
            })
            .catch((error) => {
                console.log("errorGetMe", error);
                dispatch(getUsuarios(initialState.usuarios));
            });
    };
}

export const fetchAddUsuario = (body) => {
    return dispatch => {
        apiAddUsuario(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Usuario guardado correctamente', '¡Éxito!');                    
                }
                else if (res.status === 400) {
                    NotificationManager.error('Ha ocurrido un error, ' + (res.body.message?res.body.message:(res.body.errors? res.body.errors: '') ) + '.', 'Error');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }                
                dispatch(handleModal());
            })
            .catch((error) => {
                console.log("errorAddUsuarios", error);
                dispatch(addUsuario(initialState.usuarios));
            });
    };
}

export const fetchGetInfoUsuario = (id) => {
    return dispatch => {
        apiGetUsuario(id)
            .then((usuario) => {
                dispatch(getInfoUsuario(usuario));
            })
            .catch((error) => {
                console.log("errorGetInfoUsuario", error);
                dispatch(getInfoUsuario(initialState.usuarioSeleccionado));
            });
    };
}

export const fetchUpdateUsuario = (body, id_usuario) => {
    return dispatch => {
        apiUpdateUsuario(body, id_usuario)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateUsuario(res.body));
                    NotificationManager.success('Usuario guardado correctamente', '¡Éxito!');
                }
                else if (res.status === 400) {
                    NotificationManager.error('Ha ocurrido un error, ' + res.body.message + '.', 'Error');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleModal());
            }).catch((error) => {
                console.log("errorUpdateUsuario", error);
                dispatch(updateUsuario(initialState.usuarios));
            });
    };
}

export const fetchRestoreUsuario = (id) => {
    return dispatch => {
        apiRestoreUsuario(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(restoreUsuario(id));
                    NotificationManager.success('Usuario activado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(restoreUsuario(initialState.usuarios));
                }
                dispatch(handleAlert(initialState.alertaUsuario));
            })
            .catch((error) => {
                console.log("errorRestoreUsuario", error);
                dispatch(restoreUsuario(initialState.usuarios));
            });
    };
}

export const fetchDeleteUsuario = (id) => {
    return dispatch => {
        apiDeleteUsuario(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(deleteUsuario(id));
                    NotificationManager.success('Usuario desactivado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(updateUsuario(initialState.usuarios));
                }
                dispatch(handleAlert(initialState.alertaUsuario));
            })
            .catch((error) => {
                console.log("errorDelUsuario", error);
                dispatch(deleteUsuario(initialState.usuarios));
            });
    };
}

export const fetchUpdateStatusUsuario = (id, status) => {
    switch (status) {
        case 1:
            return dispatch => {
                apiRestoreUsuario(id)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(updateUsuario(res.body));
                            NotificationManager.success('Usuario activado correctamente', '¡Éxito!');
                        }
                        else {
                            NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                            dispatch(updateUsuario(initialState.usuarios));
                        }
                        dispatch(handleAlert(initialState.alertaUsuario));
                    })
                    .catch((error) => {
                        console.log("errorActivarUsuario", error);
                        dispatch(updateUsuario(initialState.usuarios));
                    });
            };
        case 2:
            return dispatch => {
                apiDeleteUsuario(id)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(updateUsuario(res.body));
                            NotificationManager.success('Usuario desactivado correctamente', '¡Éxito!');
                        }
                        else {
                            NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                            dispatch(updateUsuario(initialState.usuarios));
                        }
                        dispatch(handleAlert(initialState.alertaUsuario));
                    })
                    .catch((error) => {
                        console.log("errorDesactivarUsuario", error);
                        dispatch(updateUsuario(initialState.usuarios));
                    });
            };
    };
}

export const fetchHandleModalUsuario = () => {
    return dispatch => {
        dispatch(handleModal());
    }
}

export const fetchHandleAlertUsuario = (tipo) => {
    switch (tipo) {
        case 1:
            return dispatch => {
                dispatch(handleAlert({
                    show: true,
                    type: 'warning',
                    title: 'Activar usuario',
                    text: '¿Está seguro que desea activar el usuario seleccionado?',
                    confirmButtonText: 'Activar',
                }));
            };
        case 2:
            return dispatch => {
                dispatch(handleAlert({
                    show: true,
                    type: 'warning',
                    title: 'Eliminar usuario',
                    text: '¿Está seguro que desea eliminar el usuario seleccionado?',
                    confirmButtonText: 'Eliminar',
                }));
            };
        default:
            return dispatch => {
                dispatch(handleAlert(initialState.alertaUsuario));
            };
    };
}

export const fetchHideAlertUsuario = () => {
    console.log('fetch hide');
    return dispatch => {
        dispatch(handleAlert(initialState.alertaUsuario));
    }
}

const usuariosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USUARIOS:
            return { ...state, usuarios: action.payload };
        case ADD_USUARIOS:
            return { ...state, usuarios: { total: state.usuarios.total + 1, rows: [...state.usuarios.rows, action.payload] } };
        case GET_INFO_USUARIO:
            return { ...state, usuarioSeleccionado: action.payload };
        case UPDATE_USUARIO:
            return { ...state, usuarios: { total: state.usuarios.total, rows: state.usuarios.rows.map(usuario => usuario.id === action.payload.id ? action.payload : usuario) } };
        case RESTORE_USUARIO:
            return { ...state, usuarios: { total: state.usuarios.total - 1, rows: state.usuarios.rows.filter(elem => elem.id !== action.payload) } };
        case DEL_USUARIO:
            return { ...state, usuarios: { total: state.usuarios.total - 1, rows: state.usuarios.rows.filter(elem => elem.id !== action.payload) } };
        case SHOW_MODAL_USUARIO:
            return { ...state, showModalUsuario: !state.showModalUsuario };
        case HANDLE_ALERT_USUARIO:
            return { ...state, alertaUsuario: action.payload };
        default:
            return { ...state };
    }
};

export default usuariosReducer;
import {
    apiGetVehiculosMonitor,
    apiGetVehiculo,
    apiDesconectarVehiculo,
    apiSendCommand,
    apiUpdateVehiculoMonitor
} from '../Api/apiMonitor';
import { sendCommandVehiculo, sendCommandDetalles } from './vehiculos';
import { NotificationManager } from 'react-notifications';

const initialState = {
    vehiculosMonitor: [],
    vehiculoSelMonitor: [],
    alertaMonitor: {
        show: false,
        type: 'warning',
        title: '',
        text: '',
        confirmButtonText: '',
    }
};

const GET_MONITOR = "GET_MONITOR";
const GET_INFO_VEHICULO_MONITOR = "GET_INFO_VEHICULO_MONITOR";
const DESCONECTAR_VEHICULO_MONITOR = "DESCONECTAR_VEHICULO_MONITOR";
const SEND_COMMAND_MONITOR = "SEND_COMMAND_MONITOR";
const SEND_COMMAND_MONITOR_DETALLES = "SEND_COMMAND_MONITOR_DETALLES";

const UPDATE_VEHICULO_MONITOR = "UPDATE_VEHICULO_MONITOR";
const HANDLE_ALERTA_MONITOR = "HANDLE_ALERTA_MONITOR";

const getMonitor = vehiculos => ({ type: GET_MONITOR, payload: vehiculos });
const sendCommandMonitor = id => ({ type: SEND_COMMAND_MONITOR, payload: id });
const sendCommandMonitorDetalles = () => ({ type: SEND_COMMAND_MONITOR_DETALLES });
const getVehiculoMonitor = vehiculo => ({ type: GET_INFO_VEHICULO_MONITOR, payload: vehiculo });
const desconectarVehiculoMonitor = () => ({ type: DESCONECTAR_VEHICULO_MONITOR });
const updateVehiculoMonitor = () => ({ type: UPDATE_VEHICULO_MONITOR });
const handleAlertMonitor = (alerta) => ({ type: HANDLE_ALERTA_MONITOR, payload: alerta });

export const fetchGetMonitor = () => {
    return dispatch => {
        apiGetVehiculosMonitor()
            .then((vehiculos) => {
                dispatch(getMonitor(vehiculos));
            })
            .catch((error) => {
                console.log("errorVehiculos", error);
                dispatch(getMonitor(initialState.vehiculosMonitor));
            });
    };
}

export const fetchGetVehiculoMonitor = (id) => {
    return dispatch => {
        apiGetVehiculo(id)
            .then((vehiculo) => {
                dispatch(getVehiculoMonitor(vehiculo));
            })
            .catch((error) => {
                console.log("errorVehiculo", error);
                dispatch(getVehiculoMonitor(initialState.vehiculoSelMonitor));
            });
    };
}

export const fetchDesconectarVehiculo = (id) => {
    return dispatch => {
        apiDesconectarVehiculo(id)
            .then((res) => {
                if (res.status === 204) {
                    dispatch(desconectarVehiculoMonitor());
                    NotificationManager.success('Vehículo desconectado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(desconectarVehiculoMonitor());
                }
            }).catch((error) => {
                console.log("errorDesconectarVehiculo", error);
            });
    };
}

export const fetchSendCommand = (body, id, vista) => {
    return dispatch => {
        apiSendCommand(body, id)
            .then(res => {
                if (res === 204 || res === '204') {
                    if (vista === 'Monitor') {
                        dispatch(sendCommandMonitor(id));
                    }
                    if (vista === 'Detalles') {
                        dispatch(sendCommandDetalles(id));
                    }
                    if (vista === 'Vehiculos') {
                        dispatch(sendCommandVehiculo(id));
                    }
                    if (vista === 'MonitorDetalles') {
                        dispatch(sendCommandMonitorDetalles());
                        dispatch(sendCommandMonitor(id));
                    }
                    NotificationManager.success('Comando enviado correctamente', '¡Éxito!');
                }
                else if (res.status === 400 || res === '400') {
                    NotificationManager.error(res.body.message, 'Error');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleAlertMonitor(initialState.alertaMonitor));
            }).catch((error) => {
                NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                console.log("errorSendCommand", error);
            });
    };
}

export const fetchUpdateVehiculo = (id) => {
    return dispatch => {
        apiUpdateVehiculoMonitor(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateVehiculoMonitor());
                    NotificationManager.success('Vehículo actualizado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(updateVehiculoMonitor(initialState.vehiculosMonitor));
                }
            }).catch((error) => {
                console.log("errorUpdateVehiculoMonitor", error);
            });
    };
}

export const fetchHandleAlertMonitor = (tipo) => {
    switch (tipo) {
        case 1:
            return dispatch => {
                dispatch(handleAlertMonitor({
                    show: true,
                    type: 'warning',
                    title: 'Encender vehículo',
                    text: '¿Está seguro que desea encender el vehículo seleccionado?',
                    confirmButtonText: 'Encender',
                }));
            };
        case 2:
            return dispatch => {
                dispatch(handleAlertMonitor({
                    show: true,
                    type: 'warning',
                    title: 'Apagar vehículo',
                    text: '¿Está seguro que desea apagar el vehículo seleccionado?',
                    confirmButtonText: 'Apagar',
                }));
            };
        default:
            return dispatch => {
                dispatch(handleAlertMonitor(initialState.alertaMonitor));
            };
    };
}

export const fetchHideAlertMonitor = () => {
    return dispatch => {
        dispatch(handleAlertMonitor(initialState.alertaMonitor));
    }
}

const monitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MONITOR:
            return { ...state, vehiculosMonitor: action.payload };
        case GET_INFO_VEHICULO_MONITOR:
            return { ...state, vehiculoSelMonitor: action.payload };
        case SEND_COMMAND_MONITOR_DETALLES:
            return { ...state, vehiculoSelMonitor: {...state.vehiculoSelMonitor, vehiculo: {...state.vehiculoSelMonitor.vehiculo, apagado: !state.vehiculoSelMonitor.vehiculo.apagado} }};
        case SEND_COMMAND_MONITOR:
            return { ...state, vehiculosMonitor: state.vehiculosMonitor.map(elm => elm.vehiculo.id === action.payload ? { ...elm, vehiculo: {...elm.vehiculo, apagado: !elm.vehiculo.apagado} } : elm) };
        case HANDLE_ALERTA_MONITOR:
            return { ...state, alertaMonitor: action.payload };
        case DESCONECTAR_VEHICULO_MONITOR:
        case UPDATE_VEHICULO_MONITOR:
            return { ...state };
        default:
            return { ...state };
    }
};

export default monitorReducer;
import React, { Component } from 'react';
import Content from '../Components/Shared/Content';
import Sidebar from '../Components/Shared/Sidebar';
import Header from '../Components/Shared/Header';

class Main extends Component {
  render() {
    return (
      <div id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper" >
          <Header />
          <Content />
        </div>
      </div>
    );
  }
}

export default Main;
import moment from 'moment';
import jwtDecode from "jwt-decode";

export const baseUrl = "https://apicelso.rosolutions.dev/";

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const makeUrlGET = (objs) => {    
    let params = [];
    for (const key in objs) {
        const val = objs[key];
        if (val) {
            params.push(`${key}=${val}`);
        }
    }
    return params.join('&');
}

export const CreateRequestReportes =  async (method, body) => {
    const token = await getToken();
     let headers = new Headers();
     headers.append("Authorization", "Bearer " + token);
     headers.append('Content-Type', 'application/json');
     headers.append('responseType', 'arraybuffer');
     let request = {
         headers,
         method
     }
     if (typeof (body) !== 'undefined') {
         request.body = JSON.stringify(body);
     }
     return request;
 };

export const CreateRequest =  async (method, body) => {
   const token = await getToken();
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + token);
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method
    }
    if (typeof (body) !== 'undefined') {
        request.body = JSON.stringify(body);
    }
    return request;
};

export const getToken = async () => {
    if (!localStorage.token || localStorage.token === 'undefined'|| localStorage.token === undefined) {        
        window.localStorage.removeItem("token");
        window.location.href = "/Login";
    }
    const currentTime = moment().unix() + 300;
    try {        
        const decoded = jwtDecode(localStorage.token);
        if (decoded.exp < currentTime) {
            const currentToken = await refreshToken();
            localStorage.setItem("token", currentToken.token);
        }
    } catch (error) {
        const currentToken = await refreshToken();
        localStorage.setItem("token", currentToken.token);
    }
    return localStorage.getItem("token");
};

const refreshToken = () => {
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + localStorage.token);
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method:POST
    }
    request.body = JSON.stringify({
        "token": localStorage.token
    });
    let url = baseUrl + 'refresh/';
    return fetch(url, request).then(res => res.json());
};
import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import { iconCarVerde, iconCarGris, iconCarRojo, iconMotoRoja, iconMotoVerde, iconMotoGris } from './Icon';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { NotificationManager } from 'react-notifications';

let position;
let zoomMap;

export default class SimpleExample extends Component {
  constructor() {
    super();
    this.state = {
      lat: 29.0667,
      lng: -110.9667,
      zoom: 11,
    };
  }

  componentDidMount() {
    this.map = this.mapInstance.leafletElement;
  }

  resetCenter() {
    const { lat, lng, zoom } = this.state;
    position = [lat, lng];
    this.map.flyTo(position, zoom)
  }

  findBounds() {
    const { bounds } = this.props;
    this.map.flyToBounds(bounds)
  }

  selectIcon(estatus, tipo, apagado) {
    if (apagado && tipo > 2) return iconCarRojo;
    if (apagado && tipo <= 2) return iconMotoRoja;
    if (!apagado && estatus === 1 && tipo > 2) return iconCarVerde;
    if (!apagado && estatus === 1 && tipo <= 2) return iconMotoVerde;
    if (!apagado && estatus === 2 && tipo > 2) return iconCarGris;
    if (!apagado && estatus === 2 && tipo <= 2) return iconMotoGris;
  }

  render() {
    const { markers, selectMarker, handleDetails, estatus, bounds } = this.props;
    const { lat, lng, zoom } = this.state;

    position = [lat, lng];
    zoomMap = zoom;

    if (bounds !== '') {
      this.findBounds(bounds);
    }
    else if (selectMarker !== -1) {
      let marcador = this.props.markers.filter(item => item.vehiculo.id === selectMarker);
      if (marcador[0].lat && marcador[0].lng) {
        position =[marcador[0].lat, marcador[0].lng];
        zoomMap = 16
      }
      else{
        NotificationManager.warning('Vehículo no encontrado', 'Error');
      } 
    }
    else {
      position = [lat, lng];
      zoomMap = zoom;
    }

    return (
      <Map center={position} zoom={zoomMap} id="cont_mapa" ref={e => { this.mapInstance = e }}>
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>
          {markers.map((marker, index) => {
            let icono;
            if (marker.vehiculo) {
              icono = this.selectIcon(marker.estado, marker.vehiculo.TipoVehiculoId, marker.vehiculo.apagado);
            }
            else {
              icono = this.selectIcon(marker.estado, 3, true)
            }
            if (marker.estado === 1 && marker.lat && marker.lng && (estatus === 1 || estatus === -1))
              return (
                <Marker key={index} position={[marker.lat, marker.lng]} icon={icono} onClick={(e) => handleDetails(e, marker.vehiculo.id)} >
                  <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                    <span> {marker.vehiculo ? marker.vehiculo.nombre ? marker.vehiculo.nombre : '' : ''} </span>
                  </Tooltip>
                </Marker>
              )
            if (marker.estado === 2 && marker.lat && marker.lng && (estatus === 2 || estatus === -1))
              return (
                <Marker key={index} position={[marker.lat, marker.lng]} icon={marker.vehiculo ? marker.vehiculo.TipoVehiculoId > 2 ? iconCarGris : iconMotoGris : iconCarGris} onClick={(e) => handleDetails(e, marker.vehiculo.id)} >
                  <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                    <span> {marker.vehiculo ? marker.vehiculo.nombre ? marker.vehiculo.nombre : '' : ''} </span>
                  </Tooltip>
                </Marker>
              )
          }
          )}
        </MarkerClusterGroup>
      </Map>
    )
  }
}
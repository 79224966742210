import {
    apiGetGasolina,
    apiAddGasolina,
    apiGetInfoRegistro,
    apiDelInfoRegistro,
    apiGetCostoGasolina
} from '../Api/apiGasolina';
import { NotificationManager } from 'react-notifications';


const initialState = {
    gasolina: {
        total: 0,
        rows: []
    },
    registroSeleccionado: [],
    costo: {
        magna: 0,
        premium: 0,
        diesel: 0
    },
};

const GET_GASOLINA = "GET_GASOLINA";
const ADD_GASOLINA = "ADD_GASOLINA";
const GET_INFO_REGISTRO = "GET_INFO_REGISTRO";
const DEL_GASOLINA = "DEL_GASOLINA";
const GET_COSTOS = "GET_COSTOS";

const getCostos = (costos) => ({ type: GET_COSTOS, payload: costos });
const getRegistros = (gasolina) => ({ type: GET_GASOLINA, payload: gasolina });
const addRegistro = (registro) => ({ type: ADD_GASOLINA, payload: registro });
const getInfoRegistro = (data) => ({ type: GET_INFO_REGISTRO, payload: data });
const delRegistro = (id) => ({ type: DEL_GASOLINA, payload: id });

export const fetchGetCostos = () => {
    return dispatch => {
        apiGetCostoGasolina()
            .then((resp) => {
                dispatch(getCostos(resp));
            })
            .catch((error) => {
                console.log("errorGetCostos", error);
                dispatch(getCostos(initialState.costo));
            });
    };
}

export const fetchGetRegistros = (obj) => {
    return dispatch => {
        apiGetGasolina(obj)
            .then((gasolina) => {
                dispatch(getRegistros(gasolina));
            })
            .catch((error) => {
                console.log("errorGetGasolina", error);
                dispatch(getRegistros(initialState.gasolina));
            });
    };
}

export const fetchAddRegistro = (body) => {
    return dispatch => {
        apiAddGasolina(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addRegistro(res.body));
                    NotificationManager.success('Vehículo guardado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    dispatch(addRegistro(initialState.gasolina));
                }
            })
            .catch((error) => {
                console.log("errorAddGasolina", error);
                dispatch(addRegistro(initialState.gasolina));
            });
    };
}

export const fetchGetRegistro = (id) => {
    return dispatch => {
        apiGetInfoRegistro(id)
            .then((resp) => {
                dispatch(getInfoRegistro(resp));
            })
            .catch((error) => {
                console.log("errorGetRegistro", error);
                dispatch(getInfoRegistro(initialState.registroSeleccionado));
            });
    };
}

export const fetchDelRegistro = (id) => {
    return dispatch => {
        apiDelInfoRegistro(id)
            .then((resp) => {
                switch (resp) {
                    case '204':
                        NotificationManager.success('Registro eliminado correctamente', '¡Éxito!');
                        dispatch(delRegistro(id));
                        break;
                    case '400':
                        NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                        console.log('Error del usuario');
                        break;
                    case '404':
                        NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                        console.log('Registro de gasolina no encontrado');
                        break;
                    case '500':
                        NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                        console.log('Error del servidor');
                        break;
                }
            })
            .catch((error) => {
                console.log("errorDelRegistro", error);
                dispatch(delRegistro(initialState.gasolina));
            });
    };
}

const gasolinaReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COSTOS:
            return { ...state, costo: action.payload };
        case GET_GASOLINA:
            return { ...state, gasolina: action.payload };
        case ADD_GASOLINA:
            return { ...state, gasolina: { total: state.gasolina.total + 1, rows: [...state.gasolina.rows, action.payload] } };
        case GET_INFO_REGISTRO:
            return { ...state, registroSeleccionado: action.payload };
        case DEL_GASOLINA:
            return { ...state, gasolina: { total: state.gasolina.total - 1, rows: state.gasolina.rows.filter(elem => elem.id !== action.payload) } };
        default:
            return { ...state };
    }
};

export default gasolinaReducer;
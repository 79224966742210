import {
    apiGetParadas,
    apiGetHistorico,
    apiGetGasolina,
    apiGetKilometros
} from '../Api/apiHistorico';

import {handleLoading} from './general';

const initialState = {
    historialVehiculo: {
        total: 0,
        rows: [],
    },
    paradasVehiculo: {
        total: 0,
        rows: [],
        distancia: 0
    },
    gasolinaVehiculo: {
        total: 0,
        distancia: 0,
        gasolina: {
            litros: 0,
            costo: 0
        },
        dias: []
    },
    kilometros: {
        total: 0,
        distancia: 0
    }
};

const GET_MAP_INFO = 'GET_MAP_INFO';
const GET_PARADAS = "GET_PARADAS";
const UNLOAD_MAP_INFO = "UNLOAD_MAP_INFO";
const GET_HISTORICO_GASOLINA = "GET_HISTORICO_GASOLINA";
const GET_KILOMETROS = "GET_KILOMETROS";

const getKilometros = kilometraje => ({ type: GET_KILOMETROS, payload: kilometraje });
const getVehiculoParadas = paradas => ({ type: GET_PARADAS, payload: paradas });
const getGasolinaVehiculo = gasolina => ({ type: GET_HISTORICO_GASOLINA, payload: gasolina });
const getMapInfo = (paradas, historial) => ({ type: GET_MAP_INFO, payload: { paradas, historial } });
const unloadMapInfo = () => ({ type: UNLOAD_MAP_INFO });

export const fetchGetParadas = (id, fecha_inicial, fecha_final) => {
    return dispatch => {
        apiGetParadas({ fecha_inicial, fecha_final }, id)
            .then((paradas) => {
                dispatch(getVehiculoParadas(paradas));
            })
            .catch((error) => {
                console.log("errorParadasVehiculo", error);
                dispatch(getVehiculoParadas(initialState.paradasVehiculo));
            });
    };
}

export const fetchMapInfo = (fecha_inicial, fecha_final, id) => {
    return dispatch => {
        dispatch(handleLoading());
        Promise.all([
            apiGetParadas({ fecha_inicial, fecha_final }, id),
            apiGetHistorico({ fecha_inicial, fecha_final }, id)
        ]).then(([paradas, historial]) => {
            dispatch(getMapInfo(paradas, historial));
            dispatch(handleLoading());
        })
    }
}

export const resetMapInfo = () => {
    return dispatch => {
        dispatch(unloadMapInfo());
    }
}

export const fetchGetGasolina = (id, fecha_inicial, fecha_final) => {
    return dispatch => {
        apiGetGasolina(id, { fecha_inicial, fecha_final })
            .then((gasolina) => {
                dispatch(getGasolinaVehiculo(gasolina));
            })
            .catch((error) => {
                console.log("errorHistorialGasolina", error);
                dispatch(getGasolinaVehiculo(initialState.gasolinaVehiculo));
            });
    };
}

export const fetchGetKilometros = (id, fecha_inicial, fecha_final) => {
    return dispatch => {
        apiGetKilometros(id, { fecha_inicial, fecha_final })
            .then((kilometros) => {
                dispatch(getKilometros(kilometros));
            })
            .catch((error) => {
                console.log("errorkilometrosVehiculo", error);
                dispatch(getKilometros(initialState.kilometros));
            });
    };
}

const historicoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_KILOMETROS:
            return { ...state, kilometros: action.payload };
        case GET_HISTORICO_GASOLINA:
            return { ...state, gasolinaVehiculo: action.payload };
        case GET_PARADAS:
            return { ...state, paradasVehiculo: action.payload };
        case GET_MAP_INFO:
            return { ...state, historialVehiculo: action.payload.historial, paradasVehiculo: action.payload.paradas };
        case UNLOAD_MAP_INFO:
            return { ...state, historialVehiculo: initialState.historialVehiculo, paradasVehiculo: initialState.paradasVehiculo };
        default:
            return { ...state };
    }
};

export default historicoReducer;
import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';

import Datatable from '../Shared/DataTable';

const initialState = {
    columns: ['Fecha', 'Chofer', 'Alerta', 'Opciones'],
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
};

const configTable = {
    columnDef: {
        alerta: (value) => (<p><b>{value.vehiculo}</b> ha emitido una alerta de {value.alerta}.</p>),
        opciones: (value) =>
            <Button type="button" className="btn btn-raised btn-info" onClick={() => window.open("http://www.google.com/maps/place/" + value.lat + "," + value.lng, "_blank")}><i className="fas fa-map-marker-alt"></i> Ver en mapa</Button>
    },
    columnStyle: {}
}

class ListaAlertas extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
    }

    componentDidMount() {
        const { size } = this.state;
        const { get, fecha_incio, fecha_fin, idVehiculo, idChofer, idTipo } = this.props;

        let obj = {
            limit: size,
            offset: '0',
            fecha_inicial: fecha_incio,
            fecha_final: fecha_fin
        }

        if(idVehiculo!== -1 ) obj.vehiculo = idVehiculo;
        if(idChofer!== -1 ) obj.chofer = idChofer;
        if(idTipo!== -1 ) obj.tipo = idTipo;

       get(obj);
    }

    updateEvent() {        
        const { size } = this.state;
        const { get, fecha_incio, fecha_fin, idVehiculo, idChofer, idTipo } = this.props;

        let obj = {
            limit: size,
            offset: '0',
            fecha_inicial: fecha_incio,
            fecha_final: fecha_fin
        }

        if(idVehiculo!== -1 ) obj.vehiculo = idVehiculo;
        if(idChofer!== -1 ) obj.chofer = idChofer;
        if(idTipo!== -1 ) obj.tipo = idTipo;

        this.setState({activePage: 1 });
        get(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size } = this.state;
        const { get, fecha_incio, fecha_fin, idVehiculo, idChofer, idTipo } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            fecha_inicial: fecha_incio,
            fecha_final: fecha_fin
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else{
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }

        if(idVehiculo!== -1 ) obj.vehiculo = idVehiculo;
        if(idChofer!== -1 ) obj.chofer = idChofer;
        if(idTipo!== -1 ) obj.tipo = idTipo;

        get(obj);
    }

    tipoAlerta(id) {
        let alerta = ''
        switch (id) {
            case 1: alerta = ' botón de pánico'
                break;
            case 2: alerta = ' batería baja'
                break;
            case 3: alerta = ' exceso de velocidad'
                break;
            case 4: alerta = ' fuera del área'
                break;
            case 5: alerta = ' pérdida de conexión (socket)'
                break;
            case 6: alerta = ' reconexión (socket)'
                break;
            case 7: alerta = ' señal de GPS mala'
                break;
            case 8: alerta = ' movimiento fuera de horario'
                break;
        }
        return alerta;
    }

    mapArray(data) {
        let dataTable = data.map((elm) => {
            return {
                fecha: moment(elm.createdAt).format('DD/MM/YYYY HH:mm'),
                chofer: null,
                alerta: {
                    vehiculo: elm.vehiculo.nombre,
                    alerta: this.tipoAlerta(elm.tipoAlerta)
                },
                opciones: {
                    lat: elm.lat,
                    lng: elm.lng
                }
            }
        });
        return dataTable;
    }

    render() {
        const { alertas } = this.props;
        const { columns, activePage, size, total} = this.state;

        return (
            <div>               
                <div className="row">
                    <div className="col-md-12">
                            <Datatable tableProps={{ columns, data: this.mapArray(alertas.rows), configTable }} total={alertas.total || total} activePage={activePage} 
                            size={size} paginacionEvent={this.paginationHandler} showSearch={false} />                            
                    </div>
                </div>
            </div>
        );
    }
}

export default ListaAlertas;